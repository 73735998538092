import React, { FC } from "react";
import styled from "styled-components";

const StyledWrapper = styled.button`
  position: relative;
  height: 52px;
  border: 0;
  color: #fff;
  font-weight: 600;
  border-radius: 52px;
  background: transparent;
  cursor: pointer;
  z-index: 2;
`;

const StyledBackground = styled.div`
  background: #7bb933;
  position: absolute;
  left: 0;
  top: 0;
  width: 75%;
  height: 100%;
  border-bottom-left-radius: 52px;
  border-top-left-radius: 52px;
  z-index: -1;
  box-shadow: 10px 10px 30px rgba(123, 185, 51, 0.3);
  will-change: width, border-bottom-right-radius, border-top-right-radius,
    background;
  transition: width 0.8s cubic-bezier(0.33, 1, 0.68, 1),
    border-bottom-right-radius 0.8s cubic-bezier(0.33, 1, 0.68, 1),
    border-top-right-radius 0.8s cubic-bezier(0.33, 1, 0.68, 1),
    background 0.8s cubic-bezier(0.33, 1, 0.68, 1),
    box-shadow 0.8s cubic-bezier(0.33, 1, 0.68, 1);

  ${StyledWrapper}:hover & {
    width: 100%;
    border-bottom-right-radius: 52px;
    border-top-right-radius: 52px;
    background: #599711;
    box-shadow: 10px 10px 30px rgba(123, 185, 51, 0.5);
  }

  ${StyledWrapper}:active & {
    transform: scale(0.95);
  }
`;

const StyledLine = styled.span`
  width: 50px;
  position: absolute;
  z-index: -1;
  top: calc(50% - 0.5px);
  right: 10px;
  background: #7bb933;
  height: 1px;
  mix-blend-mode: color-dodge;
  transition: opacity 0.8s cubic-bezier(0.33, 1, 0.68, 1),
    transform 0.8s cubic-bezier(0.33, 1, 0.68, 1);

  ${StyledWrapper}:hover & {
    opacity: 0.4;
    transform: scaleX(0);
  }
`;

const StyledContent = styled.div`
  position: absolute;
  left: 30%;
  top: 50%;
  transform: translate(-50%, -50%);
  will-change: left;
  transition: left 0.8s cubic-bezier(0.33, 1, 0.68, 1);

  ${StyledWrapper}:hover & {
    left: 50%;
  }

  ${StyledWrapper}:active & {
    transform: scale(0.95) translate(-50%, -50%);
  }
`;

const Button: FC<{
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ children, className, onClick, ...props }) => (
  <StyledWrapper className={className} onClick={onClick} {...props}>
    <StyledBackground />
    <StyledContent>{children}</StyledContent>
    <StyledLine />
  </StyledWrapper>
);

export default Button;
