export const fenceIcon = {
  body: `
<path d="M17.624 11.58H15.367C14.9161 11.58 14.4837 11.7591 14.1649 12.0779C13.8461 12.3967 13.667 12.8291 13.667 13.28C13.667 13.7308 13.8461 14.1632 14.1649 14.482C14.4837 14.8009 14.9161 14.98 15.367 14.98H17.623C18.0739 14.98 18.5063 14.8009 18.8251 14.482C19.1439 14.1632 19.323 13.7308 19.323 13.28C19.323 12.8291 19.1439 12.3967 18.8251 12.0779C18.5063 11.7591 18.0739 11.58 17.623 11.58H17.624Z" fill="currentColor"/>
<path d="M72.122 11.58H69.866C69.4152 11.58 68.9828 11.7591 68.6639 12.0779C68.3451 12.3967 68.166 12.8291 68.166 13.28C68.166 13.7308 68.3451 14.1632 68.6639 14.482C68.9828 14.8009 69.4152 14.98 69.866 14.98H72.122C72.5729 14.98 73.0053 14.8009 73.3241 14.482C73.6429 14.1632 73.822 13.7308 73.822 13.28C73.822 12.8291 73.6429 12.3967 73.3241 12.0779C73.0053 11.7591 72.5729 11.58 72.122 11.58Z" fill="currentColor"/>
<path d="M17.624 54.8789H15.367C15.1437 54.8789 14.9227 54.9229 14.7164 55.0083C14.5102 55.0937 14.3228 55.219 14.1649 55.3768C14.007 55.5347 13.8818 55.7221 13.7964 55.9283C13.711 56.1346 13.667 56.3557 13.667 56.5789C13.667 56.8022 13.711 57.0232 13.7964 57.2295C13.8818 57.4357 14.007 57.6231 14.1649 57.781C14.3228 57.9388 14.5102 58.0641 14.7164 58.1495C14.9227 58.2349 15.1437 58.2789 15.367 58.2789H17.623C18.0739 58.2789 18.5063 58.0998 18.8251 57.781C19.1439 57.4622 19.323 57.0298 19.323 56.5789C19.323 56.1281 19.1439 55.6956 18.8251 55.3768C18.5063 55.058 18.0739 54.8789 17.623 54.8789H17.624Z" fill="currentColor"/>
<path d="M72.122 54.8789H69.866C69.4152 54.8789 68.9828 55.058 68.6639 55.3768C68.3451 55.6956 68.166 56.1281 68.166 56.5789C68.166 57.0298 68.3451 57.4622 68.6639 57.781C68.9828 58.0998 69.4152 58.2789 69.866 58.2789H72.122C72.5729 58.2789 73.0053 58.0998 73.3241 57.781C73.6429 57.4622 73.822 57.0298 73.822 56.5789C73.822 56.1281 73.6429 55.6956 73.3241 55.3768C73.0053 55.058 72.5729 54.8789 72.122 54.8789Z" fill="currentColor"/>
<path d="M85.436 65.643H80.082V41.567C80.082 41.1161 79.9029 40.6837 79.5841 40.3649C79.2653 40.0461 78.8329 39.867 78.382 39.867C77.9311 39.867 77.4987 40.0461 77.1799 40.3649C76.8611 40.6837 76.682 41.1161 76.682 41.567V65.642H64.644V3.40395H76.678V26.604C76.678 27.0548 76.8571 27.4872 77.1759 27.806C77.4947 28.1248 77.9271 28.304 78.378 28.304C78.8289 28.304 79.2613 28.1248 79.5801 27.806C79.8989 27.4872 80.078 27.0548 80.078 26.604V1.70395C80.078 1.25308 79.8989 0.820685 79.5801 0.501873C79.2613 0.183061 78.8289 0.00395203 78.378 0.00395203H62.942C62.4911 0.00395203 62.0587 0.183061 61.7399 0.501873C61.4211 0.820685 61.242 1.25308 61.242 1.70395V11.582H52.989V1.70195C52.989 1.25109 52.8099 0.818682 52.4911 0.49987C52.1723 0.181058 51.7399 0.00195312 51.289 0.00195312H35.851C35.6278 0.00195312 35.4067 0.0459261 35.2004 0.131359C34.9942 0.216792 34.8068 0.342011 34.6489 0.49987C34.4911 0.65773 34.3658 0.845138 34.2804 1.05139C34.195 1.25765 34.151 1.47871 34.151 1.70195V11.58H25.9V1.70195C25.9 1.25109 25.7209 0.818682 25.4021 0.49987C25.0833 0.181058 24.6509 0.00195312 24.2 0.00195312H8.758C8.30713 0.00195312 7.87473 0.181058 7.55592 0.49987C7.23711 0.818682 7.058 1.25109 7.058 1.70195V65.642H1.7C1.24913 65.642 0.81673 65.8211 0.497918 66.1399C0.179107 66.4587 0 66.8911 0 67.3419C0 67.7928 0.179107 68.2252 0.497918 68.544C0.81673 68.8628 1.24913 69.042 1.7 69.042H85.436C85.8869 69.042 86.3193 68.8628 86.6381 68.544C86.9569 68.2252 87.136 67.7928 87.136 67.3419C87.136 66.8911 86.9569 66.4587 86.6381 66.1399C86.3193 65.8211 85.8869 65.642 85.436 65.642V65.643ZM52.989 58.284H61.24V65.643H52.989V58.284ZM61.24 14.984V54.884H52.989V14.984H61.24ZM25.9 58.283H34.151V65.642H25.9V58.283ZM34.151 14.983V54.8829H25.9V14.983H34.151ZM10.46 65.643V3.40395H22.494V65.643H10.46ZM37.553 65.643V3.40395H49.585V65.643H37.553Z" fill="currentColor"/>
<path d="M44.772 11.58H42.516C42.0651 11.58 41.6327 11.7591 41.3139 12.0779C40.9951 12.3967 40.816 12.8291 40.816 13.28C40.816 13.7308 40.9951 14.1632 41.3139 14.482C41.6327 14.8009 42.0651 14.98 42.516 14.98H44.772C45.2228 14.98 45.6552 14.8009 45.9741 14.482C46.2929 14.1632 46.472 13.7308 46.472 13.28C46.472 12.8291 46.2929 12.3967 45.9741 12.0779C45.6552 11.7591 45.2228 11.58 44.772 11.58Z" fill="currentColor"/>
<path d="M44.772 54.8789H42.516C42.0651 54.8789 41.6327 55.058 41.3139 55.3768C40.9951 55.6956 40.816 56.1281 40.816 56.5789C40.816 57.0298 40.9951 57.4622 41.3139 57.781C41.6327 58.0998 42.0651 58.2789 42.516 58.2789H44.772C45.2228 58.2789 45.6552 58.0998 45.9741 57.781C46.2929 57.4622 46.472 57.0298 46.472 56.5789C46.472 56.1281 46.2929 55.6956 45.9741 55.3768C45.6552 55.058 45.2228 54.8789 44.772 54.8789Z" fill="currentColor"/>
<path d="M79.794 33.0069C79.6018 32.7196 79.3266 32.4978 79.0052 32.3708C78.6837 32.2439 78.3312 32.218 77.9946 32.2966C77.658 32.3751 77.3533 32.5544 77.1213 32.8105C76.8892 33.0666 76.7406 33.3874 76.6955 33.73C76.6503 34.0727 76.7107 34.421 76.8686 34.7285C77.0264 35.0359 77.2742 35.288 77.579 35.451C77.8837 35.6141 78.231 35.6804 78.5743 35.641C78.9177 35.6017 79.241 35.4586 79.501 35.2309C79.8089 34.9573 80.008 34.5819 80.0618 34.1735C80.1156 33.7651 80.0206 33.351 79.794 33.0069Z" fill="currentColor"/>
`,
  width: 88,
  height: 70,
};
