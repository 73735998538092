export const questionMarkIcon = {
  body: `
<path d="M36.8533 25.5516L36.3511 25.3935C36.5018 24.9144 36.633 24.4253 36.74 23.9399L37.2544 24.0538C37.1442 24.5539 37.0093 25.0578 36.8533 25.5516Z" fill="currentColor"/>
<path d="M19.4999 38.295C16.1471 38.2943 12.8598 37.3665 10.0014 35.6143C7.14295 33.862 4.82465 31.3536 3.30273 28.3661C1.78081 25.3787 1.11455 22.0286 1.37758 18.6862C1.64061 15.3437 2.82269 12.1391 4.79317 9.42649C6.76365 6.71388 9.44579 4.59891 12.5431 3.31536C15.6405 2.03181 19.0324 1.62966 22.344 2.15336C25.6557 2.67706 28.758 4.10621 31.3081 6.28285C33.8583 8.45949 35.757 11.2989 36.7943 14.4871L36.2931 14.6495C35.0382 10.7959 32.4956 7.49152 29.0923 5.29113C25.689 3.09074 21.6322 2.12838 17.6032 2.56566C13.5741 3.00293 9.81823 4.81321 6.9662 7.69251C4.11418 10.5718 2.33972 14.3447 1.9408 18.3778C1.54188 22.4108 2.5428 26.4582 4.77547 29.8405C7.00815 33.2227 10.3366 35.7337 14.2019 36.9519C18.0672 38.17 22.2339 38.021 26.0024 36.53C29.7708 35.039 32.9115 32.2968 34.897 28.7638L35.356 29.0225C33.7706 31.8335 31.4673 34.1731 28.6814 35.8022C25.8956 37.4313 22.7271 38.2916 19.4999 38.295Z" fill="currentColor"/>
<path d="M37.6825 20.2812H37.1554V20.1126C37.1554 19.6288 37.1344 19.1397 37.0933 18.6596L37.6203 18.6143C37.663 19.1097 37.6846 19.6156 37.6846 20.1126L37.6825 20.2812Z" fill="currentColor"/>
<path d="M36.6022 25.9995C36.5486 25.9993 36.4953 25.9912 36.4441 25.9753C36.378 25.9545 36.3167 25.9208 36.2637 25.8763C36.2107 25.8318 36.1669 25.7773 36.1349 25.7159C36.103 25.6544 36.0834 25.5873 36.0774 25.5183C36.0714 25.4494 36.0791 25.3799 36.0999 25.3138C36.2485 24.8395 36.3777 24.361 36.4836 23.883C36.4985 23.8154 36.5267 23.7514 36.5663 23.6947C36.606 23.638 36.6565 23.5896 36.7148 23.5524C36.7732 23.5152 36.8383 23.4898 36.9065 23.4778C36.9746 23.4657 37.0445 23.4672 37.1121 23.4822C37.1797 23.4971 37.2436 23.5252 37.3004 23.5649C37.3571 23.6046 37.4054 23.655 37.4426 23.7134C37.4799 23.7718 37.5052 23.8369 37.5173 23.905C37.5293 23.9732 37.5278 24.0431 37.5129 24.1106C37.4006 24.6182 37.2636 25.1299 37.1055 25.6311C37.0717 25.7381 37.0047 25.8315 36.9142 25.8978C36.8237 25.964 36.7144 25.9997 36.6022 25.9995Z" fill="currentColor"/>
<path d="M19.4999 38.5584C16.0985 38.5576 12.7637 37.6163 9.86382 35.8387C6.96398 34.061 4.61212 31.5162 3.06818 28.4854C1.52425 25.4547 0.848356 22.0561 1.11522 18.6652C1.38208 15.2744 2.5813 12.0233 4.58033 9.27142C6.57937 6.51951 9.30037 4.37391 12.4426 3.07177C15.5848 1.76962 19.0259 1.36164 22.3855 1.89291C25.7451 2.42419 28.8924 3.87403 31.4795 6.08219C34.0666 8.29035 35.9928 11.1708 37.0451 14.4053C37.0702 14.4719 37.0815 14.543 37.0783 14.6141C37.0751 14.6852 37.0576 14.755 37.0267 14.8192C36.9958 14.8833 36.9523 14.9406 36.8987 14.9874C36.8451 15.0343 36.7825 15.0698 36.7148 15.0918C36.6471 15.1139 36.5756 15.1219 36.5047 15.1156C36.4338 15.1092 36.3649 15.0885 36.3022 15.0548C36.2395 15.0211 36.1842 14.975 36.1398 14.9194C36.0954 14.8637 36.0627 14.7996 36.0438 14.731C34.8079 10.9341 32.3031 7.67821 28.9502 5.50997C25.5972 3.34173 21.6003 2.39325 17.6306 2.82381C13.661 3.25437 9.96039 5.03775 7.15029 7.87445C4.3402 10.7111 2.59177 14.4284 2.19863 18.4019C1.8055 22.3755 2.7916 26.3633 4.99134 29.6956C7.19107 33.028 10.4705 35.502 14.2788 36.7021C18.0871 37.9022 22.1924 37.7553 25.9052 36.2861C29.618 34.817 32.7123 32.115 34.6683 28.634C34.7016 28.5725 34.7468 28.5182 34.8013 28.4744C34.8558 28.4305 34.9185 28.398 34.9857 28.3786C35.053 28.3593 35.1234 28.3535 35.1928 28.3616C35.2623 28.3697 35.3295 28.3916 35.3904 28.426C35.4513 28.4603 35.5048 28.5065 35.5478 28.5617C35.5907 28.6169 35.6222 28.6801 35.6404 28.7477C35.6586 28.8152 35.6632 28.8857 35.654 28.955C35.6447 29.0244 35.6217 29.0911 35.5863 29.1515C33.978 32.0034 31.6412 34.377 28.8149 36.0298C25.9886 37.6826 22.774 38.5552 19.4999 38.5584Z" fill="currentColor"/>
<path d="M37.419 20.8082C37.2792 20.8082 37.1451 20.7526 37.0463 20.6538C36.9475 20.555 36.8919 20.4209 36.8919 20.2811V20.1125C36.8919 19.6381 36.8714 19.1549 36.8308 18.6821C36.8188 18.5429 36.8626 18.4046 36.9525 18.2977C37.0425 18.1908 37.1712 18.1239 37.3104 18.1119C37.3795 18.1051 37.4492 18.1122 37.5156 18.1327C37.5819 18.1533 37.6434 18.1869 37.6965 18.2316C37.7497 18.2763 37.7933 18.3311 37.8249 18.3929C37.8566 18.4547 37.8755 18.5222 37.8806 18.5915C37.9239 19.0943 37.946 19.606 37.946 20.1125V20.2811C37.946 20.4209 37.8905 20.555 37.7916 20.6538C37.6928 20.7526 37.5587 20.8082 37.419 20.8082Z" fill="currentColor"/>
<path d="M19.5 34.3428C16.6856 34.3428 13.9345 33.5082 11.5944 31.9446C9.25432 30.381 7.43046 28.1587 6.35344 25.5585C5.27643 22.9584 4.99463 20.0972 5.54369 17.337C6.09275 14.5767 7.448 12.0412 9.43806 10.0511C11.4281 8.06103 13.9636 6.70578 16.7239 6.15673C19.4842 5.60767 22.3453 5.88947 24.9455 6.96648C27.5456 8.04349 29.768 9.86735 31.3316 12.2074C32.8952 14.5475 33.7297 17.2987 33.7297 20.113C33.7255 23.8857 32.225 27.5027 29.5573 30.1704C26.8896 32.838 23.2727 34.3386 19.5 34.3428ZM19.5 6.93736C16.8941 6.93736 14.3467 7.7101 12.18 9.15786C10.0133 10.6056 8.3245 12.6634 7.32726 15.0709C6.33003 17.4785 6.0691 20.1277 6.57749 22.6835C7.08588 25.2393 8.34074 27.587 10.1834 29.4296C12.026 31.2723 14.3737 32.5272 16.9296 33.0355C19.4854 33.5439 22.1346 33.283 24.5421 32.2858C26.9497 31.2885 29.0074 29.5998 30.4552 27.433C31.9029 25.2663 32.6757 22.7189 32.6757 20.113C32.6718 16.6198 31.2824 13.2708 28.8123 10.8007C26.3422 8.33067 22.9932 6.94127 19.5 6.93736Z" fill="currentColor"/>
<path d="M19.5 25.0467C19.3602 25.0467 19.2262 24.9912 19.1274 24.8923C19.0285 24.7935 18.973 24.6594 18.973 24.5197C18.973 22.2007 20.2147 21.0634 21.8384 19.8133C22.4167 19.3676 22.8566 18.7668 23.1067 18.0808C23.3569 17.3949 23.407 16.652 23.2514 15.9386C23.0961 15.2231 22.7383 14.5674 22.2206 14.0497C21.7029 13.532 21.0472 13.1742 20.3317 13.0189C19.7667 12.8966 19.1816 12.9022 18.6191 13.0354C18.0567 13.1685 17.5311 13.4258 17.081 13.7883C16.6371 14.1477 16.2793 14.6018 16.0338 15.1174C15.7883 15.633 15.6613 16.1971 15.6622 16.7682C15.6622 16.9079 15.6067 17.042 15.5078 17.1408C15.409 17.2397 15.275 17.2952 15.1352 17.2952C14.9954 17.2952 14.8614 17.2397 14.7625 17.1408C14.6637 17.042 14.6082 16.9079 14.6082 16.7682C14.6077 15.9363 14.8194 15.118 15.2231 14.3906C15.6268 13.6632 16.2094 13.0508 16.9156 12.6112C17.6219 12.1716 18.4285 11.9192 19.2594 11.8781C20.0903 11.8369 20.918 12.0082 21.6642 12.3759C22.4105 12.7435 23.0507 13.2954 23.5244 13.9793C23.998 14.6632 24.2895 15.4565 24.3713 16.2844C24.4531 17.1123 24.3225 17.9473 23.9918 18.7107C23.6611 19.474 23.1413 20.1405 22.4814 20.6471C20.7844 21.9573 20.027 22.8242 20.027 24.5197C20.027 24.6594 19.9715 24.7935 19.8727 24.8923C19.7738 24.9912 19.6398 25.0467 19.5 25.0467Z" fill="currentColor"/>
<path d="M19.4999 28.3478C19.3602 28.3478 19.2261 28.2923 19.1273 28.1935C19.0284 28.0946 18.9729 27.9606 18.9729 27.8208V27.551C18.9729 27.4112 19.0284 27.2771 19.1273 27.1783C19.2261 27.0795 19.3602 27.0239 19.4999 27.0239C19.6397 27.0239 19.7738 27.0795 19.8726 27.1783C19.9714 27.2771 20.027 27.4112 20.027 27.551V27.8208C20.027 27.9606 19.9714 28.0946 19.8726 28.1935C19.7738 28.2923 19.6397 28.3478 19.4999 28.3478Z" fill="currentColor"/>
`,
  width: 39,
  height: 40,
};
