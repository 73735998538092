import { Availability } from "../types/types";

export const getColorFromAvailability = (availability: Availability) => {
  switch (availability) {
    case Availability.AVAILABLE:
      return "#7BB933";
    case Availability.RESERVED:
      return "#F57600";
    case Availability.UNAVAILABLE:
      return "#CC0000";
  }
};
