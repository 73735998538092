export const windowIcon = {
  body: `
<path d="M71.5129 91.296H65.4309C65.0275 91.296 64.6405 91.1358 64.3551 90.8505C64.0697 90.5653 63.9092 90.1785 63.9089 89.775V1.52197C63.9084 1.32176 63.9474 1.12342 64.0237 0.938299C64.0999 0.75318 64.2119 0.584923 64.3533 0.443168C64.4947 0.301414 64.6627 0.188946 64.8476 0.112209C65.0325 0.0354708 65.2307 -0.00402901 65.4309 -0.00402832L71.5169 -0.00402832C71.9199 -0.00191161 72.3056 0.159925 72.5894 0.445991C72.8732 0.732057 73.032 1.119 73.031 1.52197V89.772C73.0315 90.1753 72.8719 90.5623 72.5873 90.8481C72.3027 91.1338 71.9163 91.2949 71.5129 91.296ZM66.9479 88.252H69.9919V3.04297H66.9479V88.252Z" fill="currentColor"/>
<path d="M65.4279 91.2939C65.23 91.2943 65.034 91.2559 64.8509 91.1809L31.3759 77.4869C31.0963 77.3724 30.8572 77.1772 30.6889 76.9263C30.5206 76.6754 30.4308 76.3801 30.4309 76.0779V15.2159C30.4307 14.9138 30.5204 14.6184 30.6887 14.3674C30.857 14.1165 31.0962 13.9214 31.3759 13.8069L64.8509 0.112929C65.0821 0.0177375 65.3333 -0.0189738 65.5821 0.00604565C65.8309 0.0310651 66.0697 0.117043 66.2773 0.256369C66.485 0.395694 66.6551 0.584068 66.7726 0.804814C66.8901 1.02556 66.9513 1.27187 66.9509 1.52193V89.7719C66.951 89.9719 66.9117 90.17 66.8352 90.3548C66.7587 90.5395 66.6465 90.7074 66.5051 90.8488C66.3636 90.9902 66.1957 91.1022 66.0108 91.1786C65.826 91.255 65.6279 91.2942 65.4279 91.2939ZM33.4739 75.0569L63.9049 87.5059V3.78793L33.4739 16.2369V75.0569Z" fill="currentColor"/>
<path d="M59.342 82.636C59.1327 82.6359 58.9256 82.5923 58.734 82.508L37.434 73.196C37.1629 73.0774 36.9322 72.8824 36.7701 72.6348C36.608 72.3873 36.5214 72.0979 36.521 71.802V19.495C36.5212 19.199 36.6076 18.9095 36.7698 18.6619C36.9319 18.4144 37.1628 18.2194 37.434 18.101L58.734 8.78899C58.9655 8.68784 59.2185 8.64594 59.4703 8.66708C59.722 8.68822 59.9645 8.77173 60.1759 8.91007C60.3873 9.04841 60.5609 9.23723 60.681 9.45946C60.8011 9.6817 60.864 9.93036 60.864 10.183V81.114C60.8641 81.3139 60.8249 81.5119 60.7484 81.6966C60.672 81.8813 60.5599 82.0491 60.4185 82.1905C60.2771 82.3319 60.1093 82.444 59.9246 82.5204C59.7399 82.5968 59.5419 82.6361 59.342 82.636ZM39.563 70.806L57.821 78.788V12.508L39.563 20.49V70.806Z" fill="currentColor"/>
<path d="M50.211 53.255C50.0178 53.2557 49.8263 53.219 49.647 53.147C49.4613 53.073 49.2919 52.9631 49.1487 52.8236C49.0055 52.6841 48.8913 52.5177 48.8124 52.334C48.7336 52.1503 48.6917 51.9529 48.6892 51.753C48.6867 51.5531 48.7237 51.3546 48.798 51.169L51.841 43.561C51.9539 43.2786 52.1489 43.0364 52.4007 42.8658C52.6526 42.6953 52.9498 42.6041 53.254 42.604H54.776C55.1795 42.604 55.5665 42.7643 55.8518 43.0497C56.1372 43.335 56.2974 43.722 56.2974 44.1255C56.2974 44.529 56.1372 44.916 55.8518 45.2014C55.5665 45.4867 55.1795 45.647 54.776 45.647H54.284L51.625 52.298C51.5119 52.5805 51.3167 52.8227 51.0647 52.9933C50.8127 53.1639 50.5153 53.255 50.211 53.255Z" fill="currentColor"/>
<path d="M13.6939 22.8239C11.8884 22.8237 10.1235 22.2882 8.62239 21.2849C7.12127 20.2817 5.95134 18.8559 5.26054 17.1877C4.56974 15.5196 4.3891 13.6841 4.74146 11.9133C5.09381 10.1425 5.96335 8.516 7.2401 7.23939C8.51685 5.96278 10.1435 5.09342 11.9143 4.74126C13.6851 4.3891 15.5206 4.56994 17.1887 5.26092C18.8567 5.9519 20.2824 7.12199 21.2855 8.62323C22.2885 10.1245 22.8239 11.8894 22.8239 13.6949C22.8237 16.1162 21.8616 18.4382 20.1495 20.1502C18.4373 21.8622 16.1152 22.8239 13.6939 22.8239ZM13.6939 7.60892C12.2855 7.60792 10.9203 8.09537 9.831 8.98819C8.7417 9.88101 7.99573 11.1239 7.72021 12.5052C7.44469 13.8864 7.65667 15.3204 8.32004 16.5629C8.9834 17.8053 10.0571 18.7792 11.3581 19.3187C12.6591 19.8582 14.107 19.9298 15.4549 19.5213C16.8028 19.1129 17.9673 18.2496 18.7501 17.0787C19.5328 15.9078 19.8852 14.5017 19.7474 13.1C19.6095 11.6984 18.9898 10.3879 17.9939 9.39192C16.8534 8.25135 15.3069 7.61007 13.6939 7.60892Z" fill="currentColor"/>
<path d="M13.693 7.60799C13.4932 7.60799 13.2953 7.56861 13.1107 7.49211C12.9261 7.41561 12.7584 7.30349 12.6171 7.16214C12.4759 7.0208 12.3639 6.85301 12.2875 6.66836C12.2111 6.48371 12.1719 6.28581 12.172 6.08599V1.52199C12.172 1.11846 12.3323 0.731462 12.6176 0.446125C12.903 0.160789 13.29 0.000488281 13.6935 0.000488281C14.097 0.000488281 14.484 0.160789 14.7694 0.446125C15.0547 0.731462 15.215 1.11846 15.215 1.52199V6.08599C15.215 6.48965 15.0546 6.87677 14.7692 7.1622C14.4838 7.44764 14.0967 7.60799 13.693 7.60799Z" fill="currentColor"/>
<path d="M13.693 27.389C13.4932 27.389 13.2953 27.3496 13.1107 27.2731C12.9261 27.1966 12.7584 27.0845 12.6171 26.9431C12.4759 26.8018 12.3639 26.634 12.2875 26.4494C12.2111 26.2647 12.1719 26.0668 12.172 25.867V21.303C12.172 20.8995 12.3323 20.5125 12.6176 20.2271C12.903 19.9418 13.29 19.7815 13.6935 19.7815C14.097 19.7815 14.484 19.9418 14.7694 20.2271C15.0547 20.5125 15.215 20.8995 15.215 21.303V25.867C15.215 26.2706 15.0546 26.6578 14.7692 26.9432C14.4838 27.2286 14.0967 27.389 13.693 27.389Z" fill="currentColor"/>
<path d="M9.88992 8.62793C9.62262 8.62799 9.36002 8.55769 9.12849 8.42411C8.89696 8.29053 8.70466 8.09836 8.57092 7.86693L6.28893 3.91093C6.11617 3.56426 6.08208 3.16477 6.19361 2.79384C6.30514 2.42291 6.55389 2.10846 6.88919 1.91455C7.22448 1.72064 7.62109 1.66186 7.99822 1.75019C8.37534 1.83851 8.7046 2.06729 8.91892 2.38993L11.2009 6.34593C11.3342 6.57712 11.4043 6.83928 11.4043 7.10613C11.4042 7.37297 11.334 7.63511 11.2007 7.86627C11.0674 8.09743 10.8757 8.28948 10.6447 8.42316C10.4138 8.55685 10.1518 8.62746 9.88493 8.62793H9.88992Z" fill="currentColor"/>
<path d="M19.7769 25.7569C19.5096 25.7572 19.2469 25.687 19.0153 25.5534C18.7837 25.4198 18.5915 25.2275 18.4579 24.9959L16.1759 21.0399C15.9742 20.6905 15.9196 20.2753 16.024 19.8856C16.1285 19.4959 16.3835 19.1636 16.7329 18.9619C17.0823 18.7602 17.4976 18.7056 17.8873 18.8101C18.277 18.9145 18.6092 19.1695 18.8109 19.5189L21.0939 23.4749C21.2272 23.7061 21.2973 23.9683 21.2972 24.2351C21.2972 24.502 21.227 24.7641 21.0937 24.9953C20.9604 25.2264 20.7687 25.4185 20.5377 25.5522C20.3068 25.6858 20.0448 25.7565 19.7779 25.7569H19.7769Z" fill="currentColor"/>
<path d="M7.1039 11.412C6.83704 11.4136 6.57449 11.3446 6.3429 11.212L2.3919 8.92602C2.0467 8.722 1.79596 8.39 1.69416 8.00216C1.59236 7.61432 1.64772 7.20197 1.84822 6.85471C2.04872 6.50746 2.37816 6.25336 2.76495 6.14762C3.15174 6.04189 3.56463 6.09306 3.9139 6.29002L7.8699 8.57202C8.15952 8.73979 8.38576 8.99834 8.51361 9.30765C8.64145 9.61697 8.66378 9.9598 8.57712 10.2831C8.49046 10.6064 8.29966 10.8921 8.03424 11.096C7.76883 11.2999 7.4436 11.4106 7.1089 11.411L7.1039 11.412Z" fill="currentColor"/>
<path d="M24.2369 21.302C23.9701 21.3032 23.7076 21.2343 23.4759 21.102L19.5199 18.819C19.3469 18.7191 19.1952 18.5861 19.0736 18.4276C18.952 18.2692 18.8628 18.0883 18.811 17.8953C18.7593 17.7024 18.7461 17.5011 18.7722 17.303C18.7982 17.105 18.863 16.914 18.9629 16.741C19.0628 16.5679 19.1957 16.4163 19.3542 16.2947C19.5127 16.173 19.6936 16.0838 19.8865 16.0321C20.0795 15.9804 20.2808 15.9672 20.4788 15.9932C20.6769 16.0193 20.8679 16.0841 21.0409 16.184L24.9969 18.466C25.287 18.6335 25.5137 18.8921 25.6419 19.2016C25.7701 19.5111 25.7926 19.8543 25.7059 20.1779C25.6192 20.5015 25.4281 20.7874 25.1623 20.9914C24.8966 21.1954 24.5709 21.3059 24.2359 21.306L24.2369 21.302Z" fill="currentColor"/>
<path d="M6.08596 15.216H1.51796C1.11443 15.216 0.727434 15.0557 0.442097 14.7703C0.15676 14.485 -0.00354004 14.098 -0.00354004 13.6945C-0.00354004 13.291 0.15676 12.904 0.442097 12.6186C0.727434 12.3333 1.11443 12.173 1.51796 12.173H6.08596C6.48949 12.173 6.87649 12.3333 7.16182 12.6186C7.44716 12.904 7.60746 13.291 7.60746 13.6945C7.60746 14.098 7.44716 14.485 7.16182 14.7703C6.87649 15.0557 6.48949 15.216 6.08596 15.216Z" fill="currentColor"/>
<path d="M25.867 15.216H21.302C20.8985 15.216 20.5115 15.0557 20.2262 14.7703C19.9408 14.485 19.7805 14.098 19.7805 13.6945C19.7805 13.291 19.9408 12.904 20.2262 12.6186C20.5115 12.3333 20.8985 12.173 21.302 12.173H25.867C26.2705 12.173 26.6575 12.3333 26.9429 12.6186C27.2282 12.904 27.3885 13.291 27.3885 13.6945C27.3885 14.098 27.2282 14.485 26.9429 14.7703C26.6575 15.0557 26.2705 15.216 25.867 15.216Z" fill="currentColor"/>
<path d="M3.15295 21.3019C2.81793 21.3019 2.49229 21.1913 2.22651 20.9874C1.96074 20.7834 1.76968 20.4975 1.68297 20.1739C1.59626 19.8503 1.61874 19.5071 1.74692 19.1976C1.87511 18.8881 2.10183 18.6295 2.39195 18.4619L6.34795 16.1799C6.69737 15.9782 7.1126 15.9236 7.50231 16.0281C7.89201 16.1325 8.22425 16.3875 8.42595 16.7369C8.62764 17.0864 8.68227 17.5016 8.57782 17.8913C8.47336 18.281 8.21837 18.6132 7.86895 18.8149L3.91295 21.0979C3.68199 21.2316 3.41982 21.302 3.15295 21.3019Z" fill="currentColor"/>
<path d="M20.2839 11.412C19.9492 11.4116 19.624 11.3009 19.3586 11.097C19.0932 10.8931 18.9023 10.6073 18.8157 10.2841C18.729 9.96078 18.7514 9.61795 18.8792 9.30863C19.0071 8.99931 19.2333 8.74076 19.5229 8.573L23.4789 6.291C23.8282 6.09404 24.2411 6.04286 24.6279 6.1486C25.0147 6.25433 25.3441 6.50844 25.5446 6.85569C25.7451 7.20294 25.8005 7.61529 25.6987 8.00314C25.5969 8.39098 25.3461 8.72298 25.0009 8.927L21.0449 11.209C20.8136 11.3427 20.551 11.4127 20.2839 11.412Z" fill="currentColor"/>
<path d="M7.60698 25.758C7.34014 25.7576 7.07811 25.6869 6.84716 25.5532C6.61622 25.4196 6.42448 25.2275 6.29118 24.9964C6.15787 24.7652 6.08768 24.5031 6.08765 24.2362C6.08761 23.9694 6.15773 23.7072 6.29098 23.476L8.57298 19.52C8.67285 19.347 8.80582 19.1954 8.9643 19.0737C9.12278 18.9521 9.30366 18.8629 9.49662 18.8112C9.68958 18.7594 9.89084 18.7462 10.0889 18.7723C10.287 18.7983 10.478 18.8632 10.651 18.963C10.824 19.0629 10.9756 19.1959 11.0973 19.3543C11.2189 19.5128 11.3081 19.6937 11.3598 19.8867C11.4116 20.0796 11.4248 20.2809 11.3987 20.4789C11.3727 20.677 11.3078 20.868 11.208 21.041L8.92998 24.997C8.79598 25.2291 8.60307 25.4217 8.37076 25.5553C8.13845 25.689 7.87498 25.7589 7.60698 25.758Z" fill="currentColor"/>
<path d="M17.498 8.62795C17.2311 8.62748 16.9691 8.55687 16.7382 8.42319C16.5072 8.28951 16.3155 8.09746 16.1822 7.8663C16.0489 7.63514 15.9787 7.37299 15.9786 7.10615C15.9786 6.83931 16.0487 6.57714 16.182 6.34595L18.464 2.38995C18.668 2.04475 19 1.79401 19.3878 1.69221C19.7757 1.5904 20.188 1.64577 20.5353 1.84627C20.8825 2.04677 21.1366 2.37621 21.2424 2.763C21.3481 3.14979 21.2969 3.56268 21.1 3.91195L18.818 7.86795C18.6839 8.09928 18.4914 8.29128 18.2597 8.42468C18.028 8.55808 17.7653 8.62818 17.498 8.62795Z" fill="currentColor"/>
<path d="M44.125 34.997C43.7218 34.9963 43.3353 34.8359 43.05 34.551L27.834 19.335C27.5624 19.047 27.4136 18.6645 27.4193 18.2686C27.4251 17.8728 27.5848 17.4947 27.8647 17.2147C28.1446 16.9347 28.5226 16.7748 28.9184 16.7688C29.3143 16.7629 29.6969 16.9115 29.985 17.183L45.198 32.399C45.4115 32.6118 45.5569 32.8831 45.616 33.1786C45.6751 33.4741 45.6451 33.7805 45.5299 34.059C45.4147 34.3375 45.2193 34.5755 44.9687 34.7428C44.7181 34.9101 44.4234 34.9993 44.122 34.999L44.125 34.997Z" fill="currentColor"/>
<path d="M44.125 51.7329C43.9253 51.7328 43.7276 51.6934 43.5431 51.6169C43.3587 51.5403 43.1911 51.4282 43.05 51.2869L27.834 36.0719C27.5657 35.7833 27.4198 35.4019 27.427 35.0079C27.4342 34.6139 27.5939 34.2381 27.8725 33.9595C28.1512 33.6808 28.527 33.5211 28.921 33.5139C29.315 33.5067 29.6964 33.6526 29.985 33.9209L45.198 49.1359C45.4114 49.3487 45.5569 49.62 45.616 49.9155C45.6751 50.211 45.6451 50.5175 45.5299 50.7959C45.4146 51.0744 45.2193 51.3124 44.9687 51.4797C44.718 51.6471 44.4234 51.7362 44.122 51.7359L44.125 51.7329Z" fill="currentColor"/>
<path d="M44.125 68.4699C43.9253 68.4699 43.7276 68.4304 43.5431 68.3539C43.3587 68.2774 43.1911 68.1653 43.05 68.024L27.834 52.809C27.5657 52.5204 27.4198 52.1389 27.427 51.7449C27.4342 51.351 27.5939 50.9751 27.8725 50.6965C28.1512 50.4179 28.527 50.2582 28.921 50.251C29.315 50.2438 29.6964 50.3897 29.985 50.658L45.198 65.873C45.4114 66.0857 45.5569 66.357 45.616 66.6526C45.6751 66.9481 45.6451 67.2545 45.5299 67.533C45.4146 67.8114 45.2193 68.0494 44.9687 68.2167C44.718 68.3841 44.4234 68.4733 44.122 68.473L44.125 68.4699Z" fill="currentColor"/>
<path d="M86.728 57.819C86.3248 57.8186 85.9381 57.6582 85.653 57.373L70.437 42.157C70.1687 41.8684 70.0228 41.4869 70.03 41.093C70.0372 40.699 70.197 40.3232 70.4756 40.0445C70.7542 39.7659 71.13 39.6062 71.524 39.599C71.918 39.5918 72.2994 39.7377 72.588 40.006L87.804 55.222C88.0175 55.4347 88.163 55.706 88.2221 56.0016C88.2811 56.2971 88.2512 56.6035 88.1359 56.882C88.0207 57.1604 87.8254 57.3984 87.5747 57.5658C87.3241 57.7331 87.0294 57.8223 86.728 57.822V57.819Z" fill="currentColor"/>
<path d="M86.728 74.557C86.5283 74.5571 86.3305 74.5177 86.1461 74.4412C85.9616 74.3647 85.794 74.2524 85.653 74.111L70.437 58.895C70.1687 58.6064 70.0228 58.225 70.03 57.831C70.0372 57.437 70.197 57.0612 70.4756 56.7826C70.7542 56.5039 71.13 56.3442 71.524 56.337C71.918 56.3299 72.2994 56.4757 72.588 56.744L87.804 71.959C88.0175 72.1718 88.163 72.4431 88.2221 72.7386C88.2811 73.0341 88.2512 73.3406 88.1359 73.619C88.0207 73.8975 87.8254 74.1355 87.5747 74.3028C87.3241 74.4701 87.0294 74.5593 86.728 74.559V74.557Z" fill="currentColor"/>
<path d="M86.728 91.2939C86.5283 91.2939 86.3306 91.2544 86.1462 91.1779C85.9617 91.1014 85.7941 90.9893 85.653 90.8479L70.437 75.633C70.1654 75.345 70.0166 74.9624 70.0224 74.5666C70.0281 74.1707 70.1879 73.7927 70.4678 73.5127C70.7476 73.2327 71.1256 73.0727 71.5215 73.0668C71.9173 73.0609 72.2999 73.2094 72.588 73.4809L87.804 88.696C88.0175 88.9087 88.163 89.18 88.2221 89.4756C88.2811 89.7711 88.2512 90.0775 88.1359 90.3559C88.0207 90.6344 87.8254 90.8724 87.5747 91.0398C87.3241 91.2071 87.0294 91.2963 86.728 91.2959V91.2939Z" fill="currentColor"/>
`,
  width: 89,
  height: 92,
};
