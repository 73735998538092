import React, { FC } from "react";
import { Icon } from "@iconify/react";
import { logoIcon } from "../../../assets/icons";

const Logo: FC<Props> = ({ className }) => (
  <Icon className={className} icon={logoIcon} />
);

interface Props extends React.SVGProps<SVGElement> {
  className?: string;
}

export default Logo;
