import React, { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";
import Button from "../../../atoms/Button/Button";
import { Availability, House } from "../../../../types/types";
import { formatPrice } from "../../../../utils/formatPrice";
import { formatNumber } from "../../../../utils/formatNumber";
import { getColor } from "../../../../utils/getColor";
import { Link } from "gatsby";
import { getCoords } from "../../../../utils/getCoords";

const StyledWrapper = styled.div`
  position: absolute;
  left: 5px;
  top: 5px;
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 355px;
  font-size: 1.4rem;
  font-weight: 400;
  align-items: center;
  padding-bottom: 35px;
  z-index: 999;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.07);

  @media (max-width: 1024px) {
    display: none;
  }
`;

const StyledTopWrapper = styled.div`
  padding: 25px 25px 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(23, 23, 22, 0.5);
  width: 100%;
`;

const StyledInfo = styled.div<{ $availability: Availability }>`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;

  :nth-of-type(2) {
    min-width: 121px;

    span:last-of-type {
      color: ${({ $availability }) => getColor($availability)};
    }
  }

  span:last-of-type {
    margin-top: 5px;
    font-weight: 500;
    // color: ${({ $availability }) => getColor($availability)};
    color: #7bb933;
    transition: color 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  }
`;

const StyledTable = styled.div`
  padding: 20px 25px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledItem = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;

  :last-of-type {
    margin-bottom: 0;
  }

  span:first-of-type {
    max-width: 55%;
    text-align: right;
    margin-right: 20px;
  }

  span:last-of-type {
    width: 40%;
    font-weight: 500;
  }
`;

const StyledButton = styled(Button)<{ $availability: Availability }>`
  width: 200px;
  font-size: 1.4rem;
  margin-top: 35px;
  //
  // div:first-of-type {
  //   transition: width 0.8s cubic-bezier(0.33, 1, 0.68, 1),
  //   border-bottom-right-radius 0.8s cubic-bezier(0.33, 1, 0.68, 1),
  //   border-top-right-radius 0.8s cubic-bezier(0.33, 1, 0.68, 1),
  //   filter 0.8s cubic-bezier(0.33, 1, 0.68, 1),
  //   background 0.8s cubic-bezier(0.33, 1, 0.68, 1),
  //   box-shadow 0.8s cubic-bezier(0.33, 1, 0.68, 1);!important;
  // }
  //
  // div:first-of-type,
  // span {
  //   background: ${({ $availability }) => getColor($availability)}!important;
  // }
  //
  // :hover div:first-of-type {
  //   filter: brightness(90%);
  // }
  //
  div:last-of-type {
    left: 35%;
  }
`;

const Box: FC<{
  currentHouse: House;
  isActive: boolean;
  delayHandler: NodeJS.Timeout;
  setDelayHandler: React.Dispatch<React.SetStateAction<NodeJS.Timeout>>;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  currentHouse,
  isActive,
  delayHandler,
  setDelayHandler,
  setIsActive,
}) => {
  const wrapper = useRef(null);
  const tl = gsap.timeline();

  const handleMouseOver = () => {
    setIsActive(true);

    if (delayHandler) {
      clearTimeout(delayHandler);
      setDelayHandler(null);
    }
  };
  const handleMouseLeave = () => setIsActive(false);

  useEffect(() => {
    if (isActive) {
      tl.to(wrapper.current, {
        duration: 0.5,
        scale: 1,
        opacity: 1,
        ease: "back.out(1.7)",
      }).to(wrapper.current, {
        delay: -0.3,
        duration: 0,
        pointerEvents: "all",
      });
    } else {
      tl.to(wrapper.current, {
        duration: 0.5,
        scale: 0.6,
        opacity: 0,
        ease: "back.out(1.7)",
      }).to(wrapper.current, {
        delay: -0.3,
        duration: 0,
        pointerEvents: "none",
      });
    }
  }, [isActive]);

  useEffect(() => {
    const { x, y } = getCoords(currentHouse.number);

    tl.to(wrapper.current, {
      duration: 0,
      pointerEvents: "none",
    })
      .to(wrapper.current, {
        duration: 1,
        left: x,
        top: y,
        ease: "expo.out",
      })
      .to(wrapper.current, {
        duration: 0,
        pointerEvents: "all",
        delay: -0.7,
      });
  }, [currentHouse]);

  const {
    number,
    availability,
    price,
    area,
    parcelArea,
    garage,
    deliveryDate,
    roomsCount,
  } = currentHouse;

  return (
    <StyledWrapper
      ref={wrapper}
      className="box"
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <StyledTopWrapper>
        <StyledInfo $availability={availability}>
          <span>Lokal:</span>
          <span>{number}</span>
        </StyledInfo>
        <StyledInfo $availability={availability}>
          <span>Status:</span>
          <span>{availability}</span>
        </StyledInfo>
        <StyledInfo $availability={availability}>
          <span>Cena:</span>
          <span>{formatPrice(price)}</span>
        </StyledInfo>
      </StyledTopWrapper>

      <StyledTable>
        <StyledItem>
          <span>Powierzchnia mieszkalna:</span>
          <span>
            {formatNumber(area)} m<sup>2</sup>
          </span>
        </StyledItem>
        <StyledItem>
          <span>Powierzchnia działki:</span>
          <span>
            {formatNumber(parcelArea)} m<sup>2</sup>
          </span>
        </StyledItem>
        <StyledItem>
          <span>Ilość pomieszczeń:</span>
          <span>{roomsCount}</span>
        </StyledItem>
        <StyledItem>
          <span>Miejsca postojowe:</span>
          <span>{garage}</span>
        </StyledItem>
        <StyledItem>
          <span>Termin oddania:</span>
          <span>{deliveryDate}</span>
        </StyledItem>
      </StyledTable>

      <StyledButton
        $availability={availability}
        forwardedAs={Link}
        to={`/lokal/${number.toLowerCase()}`}
      >
        Zobacz więcej
      </StyledButton>
    </StyledWrapper>
  );
};

export default Box;
