import SweetScroll from "sweet-scroll";

export const NAVIGATION_ITEMS = [
  {
    name: "Start",
    href: "/",
    onClick: (scroller: SweetScroll) => {
      scroller.to(0);
    },
    allPages: false,
  },
  { name: "O osiedlu", href: "#about_estate", allPages: false },
  { name: "Galeria", href: "#gallery", allPages: false },
  { name: "Domy", href: "#interactive_vis", allPages: true },
  { name: "Lokalizacja", href: "#localization", allPages: false },
  { name: "Standard", href: "#standards", allPages: false },
  // { name: "Nasze inwestycje", href: "#our_investments" },
  { name: "Kontakt", href: "#contact", allPages: true },
];
