import { Availability } from "../types/types";

export const getClassNameWithAvailability = (
  prefix: string,
  availability: Availability
) =>
  availability === Availability.AVAILABLE
    ? `${prefix}-available`
    : availability === Availability.RESERVED
    ? `${prefix}-reserved`
    : `${prefix}-unavailable`;
