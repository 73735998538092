export const solarPanelIcon = {
  body: `

<path d="M68.1464 7.25C61.7399 7.25 56.5464 12.4435 56.5464 18.85C56.5536 25.2536 61.7428 30.4427 68.1464 30.45C74.5528 30.45 79.7464 25.2565 79.7464 18.85C79.7464 12.4435 74.5528 7.25 68.1464 7.25ZM68.1464 27.55C63.3414 27.55 59.4464 23.6549 59.4464 18.85C59.4511 14.0471 63.3434 10.1547 68.1464 10.15C72.9513 10.15 76.8464 14.0451 76.8464 18.85C76.8464 23.6549 72.9513 27.55 68.1464 27.55Z" fill="currentColor"/>
<path d="M69.5964 0H66.6964V4.35H69.5964V0Z" fill="currentColor"/>
<path d="M69.5964 33.35H66.6964V37.7H69.5964V33.35Z" fill="currentColor"/>
<path d="M53.6464 17.4H49.2964V20.3H53.6464V17.4Z" fill="currentColor"/>
<path d="M86.9964 17.4H82.6464V20.3H86.9964V17.4Z" fill="currentColor"/>
<path d="M55.8414 4.49604L53.7908 6.54663L56.8667 9.62251L58.9173 7.57193L55.8414 4.49604Z" fill="currentColor"/>
<path d="M79.4229 28.0751L77.3723 30.1257L80.4482 33.2016L82.4988 31.151L79.4229 28.0751Z" fill="currentColor"/>
<path d="M56.867 28.0738L53.7911 31.1497L55.8417 33.2002L58.9176 30.1244L56.867 28.0738Z" fill="currentColor"/>
<path d="M80.4499 4.49565L77.374 7.57153L79.4246 9.62212L82.5005 6.54624L80.4499 4.49565Z" fill="currentColor"/>
<path d="M78.1195 84.8555L43.3195 21.0555C43.0654 20.5897 42.5771 20.2999 42.0464 20.3H1.44641C1.33748 20.3024 1.22909 20.3164 1.12306 20.3421C1.11146 20.3421 1.09986 20.3421 1.08826 20.3421C0.946888 20.38 0.8124 20.4396 0.689513 20.519C0.653263 20.5429 0.618281 20.5692 0.585113 20.5973C0.465487 20.6881 0.359819 20.7959 0.271913 20.9177C0.251613 20.9482 0.224063 20.9728 0.205213 21.0047C0.1744 21.0635 0.148119 21.1246 0.126913 21.1874C0.107881 21.2251 0.091025 21.2639 0.0761625 21.3034C0.0299438 21.4439 0.00547502 21.5905 0.00366252 21.7384V31.9C0.00348127 32.1409 0.0632938 32.3782 0.177663 32.5902L29.1704 86.2402C29.424 86.7086 29.9139 87.0004 30.4464 87H76.8464C77.6472 87.0002 78.2966 86.3512 78.2966 85.5502C78.2968 85.3075 78.2359 85.0686 78.1195 84.8555ZM65.7032 68.15H55.9577L48.8397 55.1H58.5851L65.7032 68.15ZM30.9032 52.2H19.7077L12.5897 39.15H23.7851L30.9032 52.2ZM27.0897 39.15H36.8351L43.9532 52.2H34.2077L27.0897 39.15ZM32.4851 55.1L39.6032 68.15H28.4077L21.2897 55.1H32.4851ZM35.7897 55.1H45.5351L52.6532 68.15H42.9077L35.7897 55.1ZM57.0032 52.2H47.2577L40.1397 39.15H49.8851L57.0032 52.2ZM41.1851 23.2L48.3032 36.25H38.5577L31.4397 23.2H41.1851ZM28.1351 23.2L35.2532 36.25H25.5077L18.3897 23.2H28.1351ZM15.0851 23.2L22.2032 36.25H11.0077L3.88966 23.2H15.0851ZM31.3164 84.1L2.89641 31.5332V27.4369L33.8032 84.1H31.3164ZM37.1077 84.1L29.9897 71.05H41.1851L48.3032 84.1H37.1077ZM51.6077 84.1L44.4897 71.05H54.2351L61.3532 84.1H51.6077ZM64.6577 84.1L57.5397 71.05H67.2851L74.4032 84.1H64.6577Z" fill="currentColor"/>
`,
  width: 87,
  height: 87,
};
