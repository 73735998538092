import { createGlobalStyle } from "styled-components";
import normalize from "styled-normalize";

const GlobalStyles = createGlobalStyle`
  ${normalize};
  
  *, *::before, *::after {
    box-sizing: border-box;
  }
  
  html {
    font-size: 10px;
  }
  
  body {
    font-size: 1.6rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
  }
  
  .virtual-tour {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-decoration: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 85px;
    justify-content: center;
    font-size: 1.4rem;
    text-align: center;
    padding: 10px 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    text-transform: capitalize;
    z-index: 9;
    background: #eee;
    color: #666;
    transition: background .2s ease-in-out, color .2s ease-in-out, border .2s ease-in-out;
    border: 1px solid #ddd;

    svg {
      font-size: 3rem;
      margin-bottom: 10px;
    }
    
    :hover {
      background: #7bb933;
      color: #fff;
      border: 1px solid #7bb933;
    }
    
    @media (max-width: 1024px) {
      transform: none;
      right: 15px;
      bottom: 15px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      top: unset;
      
      svg {
        margin: 0;

      }
      
      span {
        display: none;
      }
    }
  }
`;

export default GlobalStyles;
