import { ImageDataLike } from "gatsby-plugin-image";

export type ChildImageSharp = { childImageSharp: ImageDataLike };

export enum Availability {
  AVAILABLE = "Dostępny",
  UNAVAILABLE = "Niedostępny",
  RESERVED = "Zarezerwowany",
}

export type HouseId =
  | "1A"
  | "2A"
  | "3A"
  | "4A"
  | "1B"
  | "2B"
  | "3B"
  | "4B"
  | "1C"
  | "2C"
  | "3C"
  | "4C";

export interface Floor {
  name: string;
  area: number;
}

export interface House {
  number: HouseId;
  area: number;
  parcelArea: number;
  availability: Availability;
  deliveryDate: number;
  garage: string;
  roomsCount: number;
  price: number;
  firstFloor: Floor[];
  secondFloor: Floor[];
}
