import React, { FC } from "react";
import styled from "styled-components";
import { Availability } from "../../../types/types";
import { getColorFromAvailability } from "../../../utils/getColorFromAvailability";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 574px) {
    font-size: 1.4rem;
  }
`;

const StyledDot = styled.span<{ $color: string }>`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: ${({ $color }) => $color};
  margin-right: 16px;

  @media (max-width: 574px) {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
`;

const Legend: FC<{ availability: Availability; className?: string }> = ({
  availability,
  className,
}) => (
  <StyledWrapper className={className}>
    <StyledDot $color={getColorFromAvailability(availability)} />
    {availability}
  </StyledWrapper>
);

export default Legend;
