import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import Headline from "../../atoms/Headline/Headline";
import {
  Availability,
  ChildImageSharp,
  House,
  HouseId,
} from "../../../types/types";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import gsap from "gsap";
import { Draggable } from "gsap/Draggable";
import Legend from "../../molecules/Legend/Legend";
import Checkbox from "../../atoms/Checkbox/Checkbox";
import InteractiveVisualization from "./InteractiveVisualization";
import Box from "./Box/Box";
import { getColor } from "../../../utils/getColor";
import { house } from "../../../utils/house";
import { getClassNameWithAvailability } from "../../../utils/getClassNameFromeAvailability";
import { useInteractiveVisualization } from "./useInteractiveVisualization";
import { Icon } from "@iconify/react";
import { moveIcon, zoomInIcon, zoomOutIcon } from "../../../assets/icons";
import Context from "../../../context";

typeof window !== "undefined" && gsap.registerPlugin(Draggable);

const StyledWrapper = styled.section`
  margin: 180px auto 0 auto;
  width: 80%;
  max-width: 1530px;

  header {
    h2 {
      text-align: center;
    }

    p {
      font-size: 2rem;
      margin: 5px 0 0;
    }

    margin-bottom: 35px;
  }

  @media (max-width: 1024px) {
    margin: 80px auto 0 auto;
    p {
      font-size: 1.6rem !important;
      text-align: center;
    }
  }

  @media (max-width: 574px) {
    margin: 60px auto 0 auto;
  }
`;

const StyledInteractiveVisualizationContainer = styled.div`
  @media (max-width: 1024px) {
    padding-right: 30px;
    padding-bottom: 30px;
    position: relative;
  }
`;

const StyledInteractiveVisualizationWrapper = styled.div`
  position: relative;
  z-index: 99;

  @media (max-width: 1024px) {
    overflow: hidden;
  }
`;

const StyledInteractiveVisualizationInnerWrapper = styled.div`
  @media (max-width: 1024px) {
    transform-origin: left top;
    transform: scale(1) translate(0);
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

const StyledBottomWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 1024px) {
    align-items: flex-start;
    margin-top: 30px;
  }

  @media (max-width: 574px) {
    margin-top: 20px;
  }
`;

const StyledLegendsWrapper = styled.div`
  display: flex;
  margin-top: 30px;

  @media (max-width: 1024px) {
    flex-direction: column;
    margin-top: 0;
  }
`;

const StyledLegend = styled(Legend)`
  margin-right: 25px;

  :last-of-type {
    margin-right: 0;
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    margin-right: 0;
    margin-bottom: 15px;
  }

  @media (max-width: 574px) {
    margin-bottom: 10px;
  }
`;

const StyledIndicationsWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  left: 0;
  top: 0;
`;

const StyledIndicationRow = styled.div`
  display: flex;
  position: absolute;
  z-index: 20;

  :first-of-type {
    left: 20%;
    top: 13%;
    transform: rotate(-11deg);
    width: 40%;

    @media (max-width: 1024px) {
      left: 21%;
    }
  }

  :nth-of-type(2) {
    top: 20%;
    left: 35%;
    transform: rotate(-18deg);
    width: 40%;
  }

  :last-of-type {
    top: 24%;
    left: 57%;
    transform: rotate(-35deg);
    width: 40%;
  }
`;

const StyledIndication = styled(Link)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.2rem;
  font-weight: 400;

  &.indication-available,
  &.second_indication-available {
    background: ${getColor(Availability.AVAILABLE)};
  }

  &.indication-reserved,
  &.second_indication-reserved {
    background: ${getColor(Availability.RESERVED)};
  }

  &.indication-unavailable,
  &.second_indication-unavailable {
    background: ${getColor(Availability.UNAVAILABLE)};
  }

  ${StyledIndicationRow}:first-of-type & {
    transform: rotate(11deg);

    :nth-of-type(1) {
      margin-right: 8%;
    }

    :nth-of-type(2) {
      margin-right: 13%;
    }

    :nth-of-type(3) {
      margin-right: 4%;
    }

    @media (max-width: 1738px) {
      :nth-of-type(1) {
        margin-right: 6%;
      }

      :nth-of-type(2) {
        margin-right: 12%;
      }

      :nth-of-type(3) {
        margin-right: 4%;
      }
    }

    @media (max-width: 1528px) {
      :nth-of-type(1) {
        margin-right: 6%;
      }

      :nth-of-type(2) {
        margin-right: 8%;
      }

      :nth-of-type(3) {
        margin-right: 4%;
      }
    }

    @media (max-width: 1328px) {
      :nth-of-type(1) {
        margin-right: 4%;
      }

      :nth-of-type(2) {
        margin-right: 6%;
      }

      :nth-of-type(3) {
        margin-right: 3%;
      }
    }

    @media (max-width: 1195px) {
      :nth-of-type(1) {
        margin-right: 2%;
      }

      :nth-of-type(2) {
        margin-right: 4%;
      }

      :nth-of-type(3) {
        margin-right: 2%;
      }
    }

    @media (max-width: 1024px) {
      :nth-of-type(1) {
        margin-right: 9%;
      }

      :nth-of-type(2) {
        margin-right: 15%;
      }

      :nth-of-type(3) {
        margin-right: 4%;
      }
    }

    @media (max-width: 774px) {
      :nth-of-type(1) {
        margin-right: 7%;
      }

      :nth-of-type(2) {
        margin-right: 15%;
      }

      :nth-of-type(3) {
        margin-right: 1%;
      }
    }

    @media (max-width: 702px) {
      :nth-of-type(1) {
        margin-right: 6%;
      }

      :nth-of-type(2) {
        margin-right: 13%;
      }

      :nth-of-type(3) {
        margin-right: 2%;
      }
    }

    @media (max-width: 620px) {
      :nth-of-type(1) {
        margin-right: 4%;
      }

      :nth-of-type(2) {
        margin-right: 11%;
      }

      :nth-of-type(3) {
        margin-right: 2%;
      }
    }

    @media (max-width: 574px) {
      :nth-of-type(1) {
        margin-right: 6%;
      }

      :nth-of-type(2) {
        margin-right: 15%;
      }

      :nth-of-type(3) {
        margin-right: 4%;
      }
    }

    @media (max-width: 460px) {
      :nth-of-type(1) {
        margin-right: 4%;
      }

      :nth-of-type(2) {
        margin-right: 14%;
      }

      :nth-of-type(3) {
        margin-right: 2%;
      }
    }

    @media (max-width: 382px) {
      :nth-of-type(1) {
        margin-right: 3%;
      }

      :nth-of-type(2) {
        margin-right: 8%;
      }

      :nth-of-type(3) {
        margin-right: 2%;
      }
    }

    @media (max-width: 330px) {
      :nth-of-type(1) {
        margin-right: 3%;
      }

      :nth-of-type(2) {
        margin-right: 6%;
      }

      :nth-of-type(3) {
        margin-right: 2%;
      }
    }
  }

  ${StyledIndicationRow}:nth-of-type(2) & {
    transform: rotate(18deg);

    :nth-of-type(1) {
      margin-right: 7%;
    }

    :nth-of-type(2) {
      margin-right: 14%;
    }

    :nth-of-type(3) {
      margin-right: 2%;
    }

    @media (max-width: 1738px) {
      :nth-of-type(1) {
        margin-right: 6%;
      }

      :nth-of-type(2) {
        margin-right: 12%;
      }

      :nth-of-type(3) {
        margin-right: 4%;
      }
    }

    @media (max-width: 1528px) {
      :nth-of-type(1) {
        margin-right: 4%;
      }

      :nth-of-type(2) {
        margin-right: 10%;
      }

      :nth-of-type(3) {
        margin-right: 4%;
      }
    }

    @media (max-width: 1328px) {
      :nth-of-type(1) {
        margin-right: 4%;
      }

      :nth-of-type(2) {
        margin-right: 6%;
      }

      :nth-of-type(3) {
        margin-right: 3%;
      }
    }

    @media (max-width: 1195px) {
      :nth-of-type(1) {
        margin-right: 2%;
      }

      :nth-of-type(2) {
        margin-right: 4%;
      }

      :nth-of-type(3) {
        margin-right: 2%;
      }
    }

    @media (max-width: 1024px) {
      :nth-of-type(1) {
        margin-right: 9%;
      }

      :nth-of-type(2) {
        margin-right: 15%;
      }

      :nth-of-type(3) {
        margin-right: 4%;
      }
    }

    @media (max-width: 774px) {
      :nth-of-type(1) {
        margin-right: 7%;
      }

      :nth-of-type(2) {
        margin-right: 15%;
      }

      :nth-of-type(3) {
        margin-right: 1%;
      }
    }

    @media (max-width: 702px) {
      :nth-of-type(1) {
        margin-right: 6%;
      }

      :nth-of-type(2) {
        margin-right: 13%;
      }

      :nth-of-type(3) {
        margin-right: 2%;
      }
    }

    @media (max-width: 620px) {
      :nth-of-type(1) {
        margin-right: 4%;
      }

      :nth-of-type(2) {
        margin-right: 11%;
      }

      :nth-of-type(3) {
        margin-right: 2%;
      }
    }

    @media (max-width: 574px) {
      :nth-of-type(1) {
        margin-right: 6%;
      }

      :nth-of-type(2) {
        margin-right: 15%;
      }

      :nth-of-type(3) {
        margin-right: 4%;
      }
    }

    @media (max-width: 460px) {
      :nth-of-type(1) {
        margin-right: 4%;
      }

      :nth-of-type(2) {
        margin-right: 14%;
      }

      :nth-of-type(3) {
        margin-right: 2%;
      }
    }

    @media (max-width: 382px) {
      :nth-of-type(1) {
        margin-right: 3%;
      }

      :nth-of-type(2) {
        margin-right: 8%;
      }

      :nth-of-type(3) {
        margin-right: 2%;
      }
    }

    @media (max-width: 330px) {
      :nth-of-type(1) {
        margin-right: 3%;
      }

      :nth-of-type(2) {
        margin-right: 6%;
      }

      :nth-of-type(3) {
        margin-right: 2%;
      }
    }
  }

  ${StyledIndicationRow}:nth-of-type(3) & {
    transform: rotate(35deg);

    :nth-of-type(1) {
      margin-right: 6%;
    }

    :nth-of-type(2) {
      margin-right: 12%;
    }

    :nth-of-type(3) {
      margin-right: 2%;
    }

    @media (max-width: 1738px) {
      :nth-of-type(1) {
        margin-right: 4%;
      }

      :nth-of-type(2) {
        margin-right: 12%;
      }

      :nth-of-type(3) {
        margin-right: 2%;
      }
    }

    @media (max-width: 1528px) {
      :nth-of-type(1) {
        margin-right: 3%;
      }

      :nth-of-type(2) {
        margin-right: 12%;
      }

      :nth-of-type(3) {
        margin-right: 1%;
      }
    }

    @media (max-width: 1328px) {
      :nth-of-type(1) {
        margin-right: 4%;
      }

      :nth-of-type(2) {
        margin-right: 6%;
      }

      :nth-of-type(3) {
        margin-right: 3%;
      }
    }

    @media (max-width: 1195px) {
      :nth-of-type(1) {
        margin-right: 1%;
      }

      :nth-of-type(2) {
        margin-right: 4%;
      }

      :nth-of-type(3) {
        margin-right: 1%;
      }
    }

    @media (max-width: 1024px) {
      :nth-of-type(1) {
        margin-right: 6%;
      }

      :nth-of-type(2) {
        margin-right: 13%;
      }

      :nth-of-type(3) {
        margin-right: 4%;
      }
    }

    @media (max-width: 774px) {
      :nth-of-type(1) {
        margin-right: 6%;
      }

      :nth-of-type(2) {
        margin-right: 12%;
      }

      :nth-of-type(3) {
        margin-right: 1%;
      }
    }

    @media (max-width: 620px) {
      :nth-of-type(1) {
        margin-right: 4%;
      }

      :nth-of-type(2) {
        margin-right: 9%;
      }

      :nth-of-type(3) {
        margin-right: 2%;
      }
    }

    @media (max-width: 574px) {
      :nth-of-type(1) {
        margin-right: 6%;
      }

      :nth-of-type(2) {
        margin-right: 13%;
      }

      :nth-of-type(3) {
        margin-right: 3%;
      }
    }

    @media (max-width: 460px) {
      :nth-of-type(1) {
        margin-right: 4%;
      }

      :nth-of-type(2) {
        margin-right: 12%;
      }

      :nth-of-type(3) {
        margin-right: 2%;
      }
    }

    @media (max-width: 382px) {
      :nth-of-type(1) {
        margin-right: 3%;
      }

      :nth-of-type(2) {
        margin-right: 8%;
      }

      :nth-of-type(3) {
        margin-right: 2%;
      }
    }

    @media (max-width: 330px) {
      :nth-of-type(1) {
        margin-right: 3%;
      }

      :nth-of-type(2) {
        margin-right: 4%;
      }

      :nth-of-type(3) {
        margin-right: 1%;
      }
    }
  }

  @media (max-width: 1024px) {
    width: 15px;
    height: 15px;
    font-size: 0.8rem;
  }

  @media (max-width: 574px) {
    width: 10px;
    height: 10px;
    font-size: 0.5rem;
  }
`;

const StyledSecondIndicationWrapper = styled.div`
  overflow: auto;
  width: 100%;
  margin-bottom: 5px;
  padding-bottom: 10px;

  ::-webkit-scrollbar {
    height: 4px;
    background: #eeeeee;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: #7bb933;
    border-radius: 4px;

    :hover {
      background: #6aa822;
    }

    :active {
      background: #599711;
    }
  }

  @media (min-width: 1025px) {
    display: none;
  }
`;

const StyledSecondIndicationRow = styled.div`
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  min-width: 100%;
`;

const StyledSecondIndication = styled(StyledIndication)`
  width: 40px;
  height: 40px;
  font-size: 1.6rem;
  margin: 0 10px 0 0;

  :last-child {
    margin: 0;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  @media (max-width: 474px) {
    max-width: 120px;
    justify-content: flex-end;
  }
`;

const StyledButtonsWrapper = styled.div`
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;

  @media (min-width: 1025px) {
    display: none;
  }
`;

const StyledZoomButton = styled.button`
  width: 35px;
  height: 35px;
  background: 0;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7bb933;
  padding: 0;
  cursor: pointer;

  svg {
    font-size: 2.5rem;
  }

  :first-of-type {
    margin-right: 5px;
  }

  :active {
    transform: scale(0.9);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      :active {
        transform: scale(1);
      }

      color: #ccc;
    `}
`;

const StyledMoveIcon = styled(Icon)`
  position: absolute;
  font-size: 2.5rem;
  color: #7bb933;
  right: 0;
  bottom: 0;

  @media (min-width: 1025px) {
    display: none;
  }
`;

const MoveProgress = styled.div`
  border-radius: 1px;
  background: #eee;
  position: absolute;

  span {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 1px;
    background: #7bb933;
    content: "";
    transform-origin: left top;
    transition: transform 0.2s ease;
  }

  :first-of-type {
    right: 35px;
    bottom: 14px;
    width: 100px;
    height: 2px;
  }

  :last-of-type {
    bottom: 35px;
    right: 14px;
    height: 100px;
    width: 2px;
  }

  @media (min-width: 1025px) {
    display: none;
  }
`;

const houseNumbers: HouseId[] = [
  "1A",
  "2A",
  "3A",
  "4A",
  "1B",
  "2B",
  "3B",
  "4B",
  "1C",
  "2C",
  "3C",
  "4C",
];

const InteractiveVisualizationSection = () => {
  const {
    file,
    allDatoCmsHouse,
  }: { file: ChildImageSharp; allDatoCmsHouse: { nodes: House[] } } =
    useStaticQuery(query);
  const {
    currentHouseId,
    onMouseEnter,
    onMouseLeave,
    isBoxActive,
    setBoxActive,
    setDelayHandler,
    delayHandler,
  } = useInteractiveVisualization();
  const { width } = useContext(Context);
  const [isOnlyAvailable, setOnlyAvailable] = useState<boolean>(false);
  const [animating, setAnimating] = useState<boolean>(false);
  const [zoomStage, setZoomStage] = useState<number>(0);
  const [progress, setProgress] = useState<{
    currentX: number;
    maxX: number;
    currentY: number;
    maxY: number;
  }>();
  const visWrapper = useRef();
  const vis = useRef();
  const tl = gsap.timeline();

  const onZoomOutClick = () => {
    if (zoomStage === 0) return;
    setZoomStage(prevState => prevState - 1);
  };

  const onZoomInClick = () => {
    if (zoomStage === 3) return;
    setZoomStage(prevState => prevState + 1);
  };

  const resetAnimating = () => setAnimating(false);

  const isMobile = width <= 1024;

  const houses = allDatoCmsHouse.nodes;

  useEffect(() => {
    const draggable = Draggable.get(vis.current);

    if (!draggable) {
      Draggable.create(vis.current, {
        type: "x,y",
        bounds: visWrapper.current,
        edgeResistance: 0.65,
        allowEventDefault: true,
        onDrag: () => {
          const { endY, minY, endX, minX } = Draggable.get(vis.current);

          const currentX = endX > 0 ? 0 : endX < minX ? minX : endX;
          const currentY = endY > 0 ? 0 : endY < minY ? minY : endY;

          setProgress({
            currentY,
            maxY: minY,
            currentX,
            maxX: minX,
          });
        },
      });

      setProgress({
        currentY: draggable?.endY || 0,
        maxY: draggable?.minY || 1,
        currentX: draggable?.endX || 0,
        maxX: draggable?.minX || 1,
      });
    }
  }, []);

  useEffect(() => {
    const draggable = Draggable.get(vis.current);

    if (isMobile) {
      draggable?.enable();
    } else {
      draggable?.disable();
    }
  }, [isMobile]);

  useEffect(() => {
    if (isOnlyAvailable) {
      setAnimating(true);
      tl.to(
        ".path-unavailable, .path-reserved, .indication-unavailable, .indication-reserved",
        {
          pointerEvents: "none",
          duration: 0,
          delay: 0,
        }
      )
        .to(".legend-unavailable, .legend-reserved", {
          duration: 0.5,
          ease: "power4.out",
          stagger: 0.2,
          scale: 0.8,
          opacity: 0,
          pointerEvents: "none",
          y: 10,
        })
        .to(".indication-unavailable, .indication-reserved", {
          duration: 0.3,
          stagger: 0.1,
          scale: 0.8,
          opacity: 0,
          delay: -0.5,
          ease: "power4.out",
          onComplete: resetAnimating,
        })
        .to(".second_indication-unavailable, .second_indication-reserved", {
          duration: 0.3,
          stagger: 0.1,
          scale: 0.8,
          opacity: 0,
          delay: -1,
          ease: "power4.out",
          display: "none",
        });
    } else {
      tl.to(
        ".path-unavailable, .path-reserved, .indication-unavailable, .indication-reserved",
        {
          pointerEvents: "auto",
          duration: 0,
          delay: 0,
        }
      )
        .to(".legend-unavailable, .legend-reserved", {
          duration: 0.5,
          ease: "power4.out",
          stagger: 0.2,
          scale: 1,
          reversed: true,
          opacity: 1,
          pointerEvents: "auto",
          y: 0,
        })
        .to(".indication-unavailable, .indication-reserved", {
          duration: 0.3,
          stagger: 0.1,
          reversed: true,
          scale: 1,
          opacity: 1,
          delay: -0.5,
          ease: "power4.out",
          onComplete: resetAnimating,
        })
        .to(".second_indication-unavailable, .second_indication-reserved", {
          duration: 0.3,
          stagger: 0.1,
          reversed: true,
          scale: 1,
          opacity: 1,
          delay: -1,
          display: "flex",
          ease: "power4.out",
          onComplete: resetAnimating,
        });
    }
  }, [isOnlyAvailable]);

  useEffect(() => {
    const scale = (() => {
      switch (zoomStage) {
        case 0:
          return 1;
        case 1:
          return 1.5;
        case 2:
          return 2;
        case 3:
          return 3;
      }
    })();

    setProgress({
      currentY: 0,
      currentX: 0,
      maxX: 1,
      maxY: 1,
    });

    tl.to(vis.current, {
      duration: 1,
      scale,
      ease: "power4.out",
      x: 0,
      y: 0,
    });
  }, [zoomStage]);

  useEffect(() => {
    if (!isMobile && zoomStage !== 0) setZoomStage(0);
  }, [isMobile]);

  const currentHouse = house(houses, currentHouseId);

  return (
    <StyledWrapper id="interactive_vis">
      <header>
        <Headline data-aos="zoom-in-up">Domy</Headline>
        <p data-aos="fade-right" data-aos-delay="300">
          Wybierz interesujący Cię budynek, aby zobaczyć szczegóły oferty.
        </p>
      </header>

      <StyledSecondIndicationWrapper>
        <StyledSecondIndicationRow>
          {houseNumbers.map(id => (
            <StyledSecondIndication
              className={getClassNameWithAvailability(
                "second_indication",
                house(houses, id).availability
              )}
              onMouseEnter={() => onMouseEnter(id)}
              onMouseLeave={onMouseLeave}
              to={`/lokal/${id.toLowerCase()}/`}
              key={id}
            >
              {id}
            </StyledSecondIndication>
          ))}
        </StyledSecondIndicationRow>
      </StyledSecondIndicationWrapper>
      <StyledButtonsWrapper>
        <StyledZoomButton onClick={onZoomOutClick} disabled={zoomStage === 0}>
          <Icon icon={zoomOutIcon} />
        </StyledZoomButton>
        <StyledZoomButton onClick={onZoomInClick} disabled={zoomStage === 3}>
          <Icon icon={zoomInIcon} />
        </StyledZoomButton>
      </StyledButtonsWrapper>

      <StyledInteractiveVisualizationContainer>
        <StyledInteractiveVisualizationWrapper ref={visWrapper}>
          <StyledInteractiveVisualizationInnerWrapper ref={vis}>
            <StyledImage image={getImage(file.childImageSharp)} alt="" />
            <InteractiveVisualization
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              isBoxActive={isBoxActive}
              currentHouse={currentHouseId}
              houses={houses}
            />

            <StyledIndicationsWrapper>
              <StyledIndicationRow>
                <StyledIndication
                  className={getClassNameWithAvailability(
                    "indication",
                    house(houses, "1C").availability
                  )}
                  onMouseEnter={() => onMouseEnter("1C")}
                  onMouseLeave={onMouseLeave}
                  to="/lokal/1c/"
                >
                  1C
                </StyledIndication>
                <StyledIndication
                  className={getClassNameWithAvailability(
                    "indication",
                    house(houses, "2C").availability
                  )}
                  onMouseEnter={() => onMouseEnter("2C")}
                  onMouseLeave={onMouseLeave}
                  to="/lokal/2c/"
                >
                  2C
                </StyledIndication>
                <StyledIndication
                  className={getClassNameWithAvailability(
                    "indication",
                    house(houses, "3C").availability
                  )}
                  onMouseEnter={() => onMouseEnter("3C")}
                  onMouseLeave={onMouseLeave}
                  to="/lokal/3c/"
                >
                  3C
                </StyledIndication>
                <StyledIndication
                  className={getClassNameWithAvailability(
                    "indication",
                    house(houses, "4C").availability
                  )}
                  onMouseEnter={() => onMouseEnter("4C")}
                  onMouseLeave={onMouseLeave}
                  to="/lokal/4c/"
                >
                  4C
                </StyledIndication>
              </StyledIndicationRow>
              <StyledIndicationRow>
                <StyledIndication
                  className={getClassNameWithAvailability(
                    "indication",
                    house(houses, "1B").availability
                  )}
                  onMouseEnter={() => onMouseEnter("1B")}
                  onMouseLeave={onMouseLeave}
                  to="/lokal/1b/"
                >
                  1B
                </StyledIndication>
                <StyledIndication
                  className={getClassNameWithAvailability(
                    "indication",
                    house(houses, "2B").availability
                  )}
                  onMouseEnter={() => onMouseEnter("2B")}
                  onMouseLeave={onMouseLeave}
                  to="/lokal/2b/"
                >
                  2B
                </StyledIndication>
                <StyledIndication
                  className={getClassNameWithAvailability(
                    "indication",
                    house(houses, "3B").availability
                  )}
                  onMouseEnter={() => onMouseEnter("3B")}
                  onMouseLeave={onMouseLeave}
                  to="/lokal/3b/"
                >
                  3B
                </StyledIndication>
                <StyledIndication
                  className={getClassNameWithAvailability(
                    "indication",
                    house(houses, "4B").availability
                  )}
                  onMouseEnter={() => onMouseEnter("4B")}
                  onMouseLeave={onMouseLeave}
                  to="/lokal/4b/"
                >
                  4B
                </StyledIndication>
              </StyledIndicationRow>
              <StyledIndicationRow>
                <StyledIndication
                  className={getClassNameWithAvailability(
                    "indication",
                    house(houses, "1A").availability
                  )}
                  onMouseEnter={() => onMouseEnter("1A")}
                  to="/lokal/1a/"
                >
                  1A
                </StyledIndication>
                <StyledIndication
                  className={getClassNameWithAvailability(
                    "indication",
                    house(houses, "2A").availability
                  )}
                  onMouseEnter={() => onMouseEnter("2A")}
                  to="/lokal/2a/"
                >
                  2A
                </StyledIndication>
                <StyledIndication
                  className={getClassNameWithAvailability(
                    "indication",
                    house(houses, "3A").availability
                  )}
                  onMouseEnter={() => onMouseEnter("3A")}
                  to="/lokal/3a/"
                >
                  3A
                </StyledIndication>
                <StyledIndication
                  className={getClassNameWithAvailability(
                    "indication",
                    house(houses, "4A").availability
                  )}
                  onMouseEnter={() => onMouseEnter("4A")}
                  to="/lokal/4a/"
                >
                  4A
                </StyledIndication>
              </StyledIndicationRow>
            </StyledIndicationsWrapper>

            <Box
              isActive={isBoxActive}
              currentHouse={currentHouse}
              delayHandler={delayHandler}
              setDelayHandler={setDelayHandler}
              setIsActive={setBoxActive}
            />
          </StyledInteractiveVisualizationInnerWrapper>
        </StyledInteractiveVisualizationWrapper>

        <StyledMoveIcon icon={moveIcon} />
        <div>
          <MoveProgress>
            <span
              style={{
                transform:
                  zoomStage !== 0
                    ? `scaleX(${progress?.currentX / progress?.maxX})`
                    : "scaleX(0)",
              }}
            />
          </MoveProgress>
          <MoveProgress>
            <span
              style={{
                transform:
                  zoomStage !== 0
                    ? `scaleY(${progress?.currentY / progress?.maxY})`
                    : "scaleY(0)",
              }}
            />
          </MoveProgress>
        </div>
      </StyledInteractiveVisualizationContainer>

      <StyledBottomWrapper>
        <StyledLegendsWrapper>
          <StyledLegend
            availability={Availability.AVAILABLE}
            className="legend-available"
          />
          <StyledLegend
            availability={Availability.RESERVED}
            className="legend-reserved"
          />
          <StyledLegend
            availability={Availability.UNAVAILABLE}
            className="legend-unavailable"
          />
        </StyledLegendsWrapper>
        <StyledCheckbox
          onChange={() => setOnlyAvailable(prevState => !prevState)}
          checked={isOnlyAvailable}
          label="Pokaż tylko dostępne"
        />
      </StyledBottomWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    file(name: { eq: "vis" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }
    allDatoCmsHouse {
      nodes {
        number
        price
        deliveryDate
        availability
        parcelArea
        area
        garage
        roomsCount
      }
    }
  }
`;

export default InteractiveVisualizationSection;
