import { useState, MouseEvent } from "react";
import { HouseId } from "../../../types/types";

export const useInteractiveVisualization = () => {
  const [isBoxActive, setBoxActive] = useState(false);
  const [currentHouse, setCurrentHouse] = useState<HouseId>("1A");
  const [delayHandler, setDelayHandler] = useState<NodeJS.Timeout>(null);

  const onMouseEnter = (id: HouseId) => {
    if (delayHandler) {
      clearTimeout(delayHandler);
      setDelayHandler(null);
    }
    setBoxActive(true);

    setCurrentHouse(id);
  };

  const onMouseLeave = (e: MouseEvent<SVGElement | HTMLAnchorElement>) => {
    const { relatedTarget } = e as any;

    if (
      (relatedTarget instanceof HTMLElement ||
        relatedTarget instanceof SVGElement) &&
      (relatedTarget.classList.contains("box") ||
        relatedTarget.nodeName === "path" ||
        relatedTarget.nodeName === "g" ||
        relatedTarget.nodeName === "A")
    ) {
      return;
    }

    setDelayHandler(
      setTimeout(() => {
        setBoxActive(false);
      }, 300)
    );
  };

  return {
    isBoxActive,
    setBoxActive,
    currentHouseId: currentHouse,
    delayHandler,
    setDelayHandler,
    onMouseLeave,
    onMouseEnter,
  };
};
