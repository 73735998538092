import React, { FC, useContext } from "react";
import styled, { css } from "styled-components";
import { NAVIGATION_ITEMS } from "../../../config/navigation";
import { Icon } from "@iconify/react";
import facebookIcon from "@iconify/icons-fe/facebook";
import instagramIcon from "@iconify/icons-fe/instagram";
import Logo from "../../atoms/Logo/Logo";
import Context from "../../../context";
import { graphql, Link, useStaticQuery } from "gatsby";

const hoverStyles = css`
  ::before {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #171716;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transform-origin: left center;
    transition: transform 0.6s cubic-bezier(0.33, 1, 0.68, 1);
  }

  :hover::before {
    transform: scaleX(1);
  }
`;

const StyledWrapper = styled.footer`
  background: rgba(238, 238, 238, 0.3);
  padding: 120px 0 60px 0;
  color: #171716;

  @media (max-width: 1024px) {
    padding: 80px 0 40px;
  }
`;

const StyledInnerWrapper = styled.div`
  width: 90%;
  max-width: 1250px;
  margin: auto;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledPhoneNumber = styled.div`
  font-weight: 500;
  font-size: 2.6rem;
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: 0 0;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const StyledList = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  flex-direction: column;
  margin: 0 0 0 -10%;

  @media (max-width: 1024px) {
    margin: 0 0 40px;
    align-items: center;
  }
`;

const StyledItem = styled.li`
  text-transform: uppercase;
  margin-bottom: 12px;
  font-weight: 300;

  :last-of-type {
    margin-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    position: relative;
    padding-bottom: 2px;

    ${hoverStyles};
  }

  @media (max-width: 1024px) {
    margin-bottom: 20px;
  }
`;

const StyledSocialMediaWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    align-items: center;
  }
`;

const StyledSocialMedia = styled.a`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 6px;
  color: inherit;
  text-decoration: none;

  :first-of-type {
    margin-bottom: 12px;
  }

  svg {
    margin-right: 10px;
    font-size: 2rem;
  }

  :hover {
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    :first-of-type {
      margin-bottom: 20px;
    }
  }

  ${hoverStyles};
`;

const StyledLogo = styled(Logo)`
  font-size: 24rem;
  color: #393e42;

  @media (max-width: 1024px) {
    font-size: 15rem;
    order: -1;
    margin-bottom: 40px;
  }
`;

const StyledCopyright = styled.div`
  display: flex;
  border-top: 1px solid #000;
  padding-top: 20px;
  margin-top: 75px;
  justify-content: space-between;

  span:last-child {
    font-size: 1.2rem;
    max-width: 340px;
    line-height: 1.5;
  }

  @media (max-width: 1024px) {
    margin-top: 50px;
    flex-direction: column;
    align-items: center;
    text-align: center;

    span:last-child {
      margin-top: 20px;
      width: 90%;
      max-width: unset;
    }
  }
`;

const Footer: FC<{ isAnotherThanIndex: boolean }> = ({
  isAnotherThanIndex,
}) => {
  const { scroller } = useContext(Context);
  const {
    datoCmsContact,
  }: {
    datoCmsContact: {
      phoneNumber: string;
      instagramLink: string;
      facebookLink: string;
    };
  } = useStaticQuery(query);

  return (
    <StyledWrapper>
      <StyledInnerWrapper>
        <StyledContainer>
          <StyledPhoneNumber>{datoCmsContact.phoneNumber}</StyledPhoneNumber>
          <StyledList>
            {NAVIGATION_ITEMS.map(
              ({ name, href, onClick, allPages }, index) => (
                <StyledItem key={index}>
                  {isAnotherThanIndex && !allPages ? (
                    <Link to={href.includes("/") ? href : `/${href}`}>
                      {name}
                    </Link>
                  ) : (
                    <a
                      href={href}
                      data-scroll={!onClick ? "" : undefined}
                      onClick={
                        onClick
                          ? e => {
                              e.preventDefault();
                              onClick(scroller);
                            }
                          : undefined
                      }
                    >
                      {name}
                    </a>
                  )}
                </StyledItem>
              )
            )}
          </StyledList>
          <StyledSocialMediaWrapper>
            <StyledSocialMedia href={datoCmsContact.facebookLink}>
              <Icon icon={facebookIcon} /> facebook
            </StyledSocialMedia>
            <StyledSocialMedia href={datoCmsContact.instagramLink}>
              <Icon icon={instagramIcon} /> instagram
            </StyledSocialMedia>
          </StyledSocialMediaWrapper>
          <StyledLogo />
        </StyledContainer>

        <StyledCopyright>
          <span>
            2021 &copy; Osiedle pod miastem Kunów. All rights reserved.
          </span>
          <span>
            Treści znajdujące się na stronie internetowej nie stanowią oferty w
            rozumieniu przepisów prawa.
          </span>
        </StyledCopyright>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    datoCmsContact {
      phoneNumber
      instagramLink
      facebookLink
    }
  }
`;

export default Footer;
