export const emailIcon = {
  body: `
<path d="M30.6125 16.1114L46.1813 25.9538V5.85345L30.6125 16.1114Z" fill="currentColor"/>
<path d="M0 5.85345V25.9538L15.5688 16.1114L0 5.85345Z" fill="currentColor"/>
<path d="M43.2951 0H2.88664C1.44637 0 0.303388 1.07371 0.0869141 2.45626L23.0909 17.6123L46.0948 2.45626C45.8784 1.07371 44.7354 0 43.2951 0Z" fill="currentColor"/>
<path d="M27.9686 17.8555L23.8845 20.5455C23.642 20.7043 23.3678 20.7822 23.0907 20.7822C22.8136 20.7822 22.5394 20.7043 22.297 20.5455L18.2128 17.8526L0.0925293 29.3141C0.314776 30.6851 1.45199 31.7502 2.88649 31.7502H43.295C44.7295 31.7502 45.8667 30.6851 46.0889 29.3141L27.9686 17.8555Z" fill="currentColor"/>
`,
  width: 47,
  height: 32,
};
