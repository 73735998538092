import { HouseId } from "../types/types";

export const getCoords = (id: HouseId) => {
  switch (id) {
    case "1C":
      return { x: "25%", y: "30%" };
    case "2C":
      return { x: "30%", y: "28%" };
    case "3C":
      return { x: "35%", y: "25%" };
    case "4C":
      return { x: "40%", y: "23%" };
    case "1B":
      return { x: "37%", y: "48%" };
    case "2B":
      return { x: "45%", y: "40%" };
    case "3B":
      return { x: "52%", y: "37%" };
    case "4B":
      return { x: "56%", y: "32%" };
    case "1A":
      return { x: "60%", y: "53%" };
    case "2A":
      return { x: "65%", y: "50%" };
    case "3A":
      return { x: "70%", y: "45%" };
    case "4A":
      return { x: "75%", y: "38%" };
    default:
      return { x: 0, y: 0 };
  }
};
