import React, { forwardRef } from "react";
import styled, { css } from "styled-components";
import { ChangeHandler } from "react-hook-form";

const StyledWrapper = styled.label<{ $error?: string }>`
  position: relative;
  display: block;
  transition: color 0.2s ease-in-out;
  color: rgba(0, 0, 0, 0.5);

  textarea {
    width: 100%;
    border: 0;
    outline: none;
    font-size: 1.6rem;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #000;
    transition: border-bottom 0.2s ease-in-out;
    background: transparent;
    height: 100px;
    resize: none;
  }

  span {
    position: absolute;
    font-size: 1.6rem;
    width: 100%;
    left: 0;
    top: 0;
    pointer-events: none;
    transform-origin: left top;
    transition: transform 0.2s ease-in-out;
    text-transform: uppercase;
    font-weight: 400;
  }

  textarea:not(:placeholder-shown) ~ span {
    transform: translateY(-18px) scale(0.6);
  }
  ${({ $error }) =>
    $error &&
    css`
      color: #e60000;
      textarea {
        border-bottom: 1px solid #e60000;
        color: #e60000;
      }
    `}
`;

const StyledError = styled.div`
  font-size: 1.2rem;
  display: block;
  margin-top: 10px;
  pointer-events: none;
  user-select: none;
`;

const Textarea = forwardRef<
  HTMLTextAreaElement,
  {
    label: string;
    className?: string;
    onChange?: ChangeHandler;
    onBlur?: ChangeHandler;
    name?: string;
    error?: string;
  }
>(({ label, className, onChange, onBlur, name, error }, ref) => {
  return (
    <StyledWrapper className={className} $error={error}>
      <textarea
        placeholder=" "
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
      />
      <span>{label}</span>
      {error && <StyledError>{error}</StyledError>}
    </StyledWrapper>
  );
});

export default Textarea;
