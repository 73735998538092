import React, { FC, useEffect, useState } from "react";
import GlobalStyles from "../theme/GlobalStyles";
import { Helmet } from "react-helmet";
import Footer from "../components/molecules/Footer/Footer";
import SweetScroll from "sweet-scroll";
import Context from "../context";
import { Icon } from "@iconify/react";
import { view360Icon } from "../assets/icons";
import { graphql, useStaticQuery } from "gatsby";
import { useWindowSize } from "react-use";
import aos from "aos";
import "aos/dist/aos.css";
import Loader from "../components/atoms/Loader/Loader";
import { CSSTransition } from "react-transition-group";

const BasicTemplate: FC<{
  header?: FC;
  isAnotherThanIndex?: boolean;
  title?: string;
  setWidth?: React.Dispatch<React.SetStateAction<number>>;
  setHeight?: React.Dispatch<React.SetStateAction<number>>;
}> = ({
  children,
  isAnotherThanIndex,
  header: Header,
  title,
  setWidth,
  setHeight,
}) => {
  const [scroller, setScroller] = useState<SweetScroll>();
  const [isLoaded, setLoaded] = useState<boolean>(isAnotherThanIndex);
  const { width, height } = useWindowSize();

  const {
    file: {
      childImageSharp: {
        gatsbyImageData: {
          images: {
            fallback: { src: ogImage },
          },
        },
      },
    },
  } = useStaticQuery(query);

  useEffect(() => {
    aos.init({
      duration: 500,
      easing: "ease-out-cubic",
      once: true,
    });

    setScroller(
      new SweetScroll({
        duration: 2000,
        offset: -100,
      })
    );

    const getVh = () => {
      const vh = typeof window !== "undefined" && window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    getVh();
    setTimeout(() => setLoaded(true), 300);

    window.addEventListener("resize", getVh);

    return () => {
      window.removeEventListener("resize", getVh);
    };
  }, []);

  const href = typeof window !== "undefined" && window.location.href;

  useEffect(() => {
    if (href.includes("#")) {
      const id = `#${href.split("#")[1]}`;
      scroller?.to(id);
    }
  }, [href]);

  useEffect(() => {
    if (setWidth && setHeight) {
      setWidth(width);
      setHeight(height);
    }
  }, [width, height]);

  const readyTitle = title
    ? `${title} | Osiedle pod miastem Kunów`
    : "Osiedle pod miastem Kunów";
  const description =
    "Osiedle pod miastem - monitorowany i zamknięty kompleks składający się z 12 domów w zabudowie bliźniaczej. Domy znajdują się w spokojnej okolicy w miejscowości Kunów.";

  return (
    <>
      <Helmet>
        <html lang="pl" />
        <title>{readyTitle}</title>
        <meta name="description" content={description} />

        <meta property="og:image" content={ogImage} />
        <meta property="og:title" content={readyTitle} />
        <meta property="og:description" content={description} />
      </Helmet>
      <GlobalStyles />

      <CSSTransition
        in={!isLoaded}
        timeout={500}
        classNames="loader"
        unmountOnExit
      >
        <Loader />
      </CSSTransition>

      <Context.Provider value={{ scroller, width, height }}>
        <header>{Header && <Header />}</header>
        <main>{children}</main>
        <Footer isAnotherThanIndex={isAnotherThanIndex} />

        <a className="virtual-tour" href="/wirtualny-spacer" target="_blank">
          <Icon icon={view360Icon} />
          <span>Wirtualny spacer</span>
        </a>
      </Context.Provider>
    </>
  );
};

const query = graphql`
  {
    file(name: { eq: "hero" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, formats: JPG, layout: FIXED, width: 1200)
      }
    }
  }
`;

export default BasicTemplate;
