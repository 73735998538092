import React, { FC } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import checkIcon from "@iconify/icons-akar-icons/check";
import { ChangeHandler } from "react-hook-form";

const StyledWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: 574px) {
    font-size: 1.4rem;
  }
`;

const StyledCheckboxWrapper = styled.div`
  width: 26px;
  height: 26px;
  position: relative;
  margin-right: 15px;

  @media (max-width: 674px) {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`;

const StyledInput = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
`;

const StyledCheckbox = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #000;
  color: #fff;
  font-size: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  ${StyledInput}:checked + & {
    background: #000;
  }
`;

const StyledError = styled.span`
  font-size: 1.2rem;
  color: #e60000;
  pointer-events: none;
  margin-top: 10px;
  display: block;
`;

const Checkbox = React.forwardRef<
  HTMLInputElement,
  {
    id?: string;
    className?: string;
    label: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    checked?: boolean;
    onBlur?: ChangeHandler;
    name?: string;
    error?: string;
    disabled?: boolean;
  }
>(
  (
    { label, id, className, onChange, checked, disabled, onBlur, name, error },
    ref
  ) => {
    return (
      <div className={className}>
        <StyledWrapper>
          <StyledCheckboxWrapper>
            <StyledInput
              id={id}
              onChange={onChange}
              checked={checked}
              ref={ref}
              name={name}
              onBlur={onBlur}
              type="checkbox"
              disabled={disabled}
            />
            <StyledCheckbox>
              <Icon icon={checkIcon} />
            </StyledCheckbox>
          </StyledCheckboxWrapper>
          {label}
        </StyledWrapper>
        {error && <StyledError>{error}</StyledError>}
      </div>
    );
  }
);

export default Checkbox;
