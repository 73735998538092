import React from "react";
import styled from "styled-components";

export default styled.h2`
  font-size: 6.4rem;
  color: #7bb933;
  font-family: "Bebas Neue", sans-serif;
  margin: 0 0 15px 0;
  letter-spacing: 2px;

  @media (max-width: 1024px) {
    text-align: center;
    font-size: 4.2rem;
  }

  @media (max-width: 574px) {
    font-size: 3.4rem;
  }
`;
