import { createContext } from "react";
import SweetScroll from "sweet-scroll";

interface Context {
  scroller: SweetScroll;
  width: number;
  height: number;
}

const Context = createContext<Context>({
  scroller: null,
  width: typeof window !== "undefined" ? window.innerWidth : 1920,
  height: typeof window !== "undefined" ? window.innerHeight : 1920,
});

export default Context;
