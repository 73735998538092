import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Headline from "../../atoms/Headline/Headline";
import Input from "../../atoms/Input/Input";
import Textarea from "../../atoms/Textarea/Textarea";
import ReCaptcha from "react-google-recaptcha";
import Checkbox from "../../atoms/Checkbox/Checkbox";
import Button from "../../atoms/Button/Button";
import { Icon } from "@iconify/react";
import { emailIcon, markerIcon, phoneIcon } from "../../../assets/icons";
import { useForm } from "react-hook-form";
import axios from "axios";
import { graphql, useStaticQuery } from "gatsby";

const StyledWrapper = styled.section`
  width: 90%;
  max-width: 1615px;
  margin: 240px auto;
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    margin: 80px auto;
  }
`;

const StyledLeftColumn = styled.div`
  max-width: 1122px;
  width: 100%;
  background: rgba(238, 238, 238, 0.3);
  padding: 85px 60px 40px;
  min-height: 730px;
  display: flex;
  flex-direction: column;

  h2 {
    letter-spacing: 0.1em;
    text-align: center;
  }

  @media (max-width: 574px) {
    padding: 60px 10% 40px;
  }
`;

const StyledForm = styled.form`
  margin-top: 80px;

  > div {
    display: flex;
    justify-content: space-between;

    :first-child {
      margin-bottom: 70px;
    }

    label {
      width: 45%;
    }

    @media (max-width: 1186px) {
      :last-child {
        flex-direction: column;
      }
    }
  }

  @media (max-width: 574px) {
    > div {
      flex-direction: column;

      :first-child {
        margin-bottom: 20px;

        label {
          width: 100%;
          margin-bottom: 50px;
        }
      }
    }
  }
`;

const StyledReCaptchaWrapper = styled.div`
  margin: 30px 0;
  position: relative;
  display: flex;
  flex-direction: column;

  div {
    margin: 0 !important;
  }

  span {
    font-size: 1.2rem;
    color: #e60000;
    margin-top: 10px;
    display: block;
  }

  iframe {
    transform-origin: left top;
  }

  @media (max-width: 370px) {
    margin: 30px 0 10px;

    iframe {
      transform: scale(0.8);
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  label {
    font-size: 1.2rem;
    width: 360px !important;
    align-items: flex-start;

    @media (max-width: 574px) {
      width: unset !important;
    }
  }

  div {
    margin-bottom: 0 !important;
  }

  display: flex;
  flex-direction: column;
`;

const StyledButton = styled(Button)`
  width: 145px;

  @media (max-width: 1186px) {
    margin-top: 30px;
  }

  @media (max-width: 574px) {
    margin: 30px auto 0 auto;
  }
`;

const StyledRightColumn = styled.div`
  margin-left: 120px;

  @media (max-width: 1324px) {
    margin-left: 60px;
  }

  @media (max-width: 1024px) {
    margin-left: 0;
    margin-top: 80px;
  }
`;

const StyledContactBlock = styled.a`
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  font-weight: 400;
  color: inherit;
  text-decoration: none;
  line-height: 1.5;

  :last-of-type {
    margin-bottom: 0;
  }

  svg {
    font-size: 4rem;
    color: #7bb933;
    margin-right: 32px;
  }

  :last-of-type {
    svg {
      font-size: 3.1rem;
    }
  }

  @media (max-width: 1024px) {
    svg {
      font-size: 5rem;
      margin-right: 0;
      margin-bottom: 20px;
    }

    flex-direction: column;
  }

  @media (max-width: 574px) {
    font-size: 1.6rem;
  }
`;

const StyledAfterSendMessage = styled.div<{ $success: boolean }>`
  font-size: 1.8rem;
  color: ${({ $success }) => ($success ? "#398000" : "#e60000")};
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    datoCmsContact,
  }: {
    datoCmsContact: {
      phoneNumber: string;
      location: string;
      email: string;
      googleLink: string;
    };
  } = useStaticQuery(query);
  const [captchaKey, setCaptchaKey] = useState<string>();
  const [wasSendTry, setWasSendTry] = useState<boolean>(false);
  const [successfulSend, setSuccessfulSend] = useState<boolean>(false);
  const [errorSend, setErrorSend] = useState<boolean>(false);

  const onSubmit = (data: {
    name: string;
    email: string;
    message: string;
    consent: boolean;
  }) => {
    if (!captchaKey) {
      setWasSendTry(true);
      return;
    }

    axios
      .post("https://formsubmit.co/ajax/kontakt@osiedlepodmiastem.pl", {
        "Imię i nazwisko": data.name,
        "E-mail": data.email,
        "Wiadomość ": data.message,
      })
      .then(res =>
        res.status === 200 ? setSuccessfulSend(true) : setErrorSend(true)
      )
      .catch(() => setErrorSend(true));
  };

  useEffect(() => {
    if (
      (errors.message || errors.name || errors.email || errors.consent) &&
      !captchaKey
    ) {
      setWasSendTry(true);
    }
  }, [errors.message, errors.name, errors.email, errors.consent]);

  const locationArray = datoCmsContact.location.split(",");

  return (
    <StyledWrapper id="contact">
      <StyledLeftColumn>
        <header>
          <Headline data-aos="zoom-in-up">Skontaktuj się z nami!</Headline>
        </header>

        {!successfulSend && !errorSend ? (
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Input
                label="Imię i nazwisko"
                error={
                  errors.name ? "Imię i nazwisko jest wymagane." : undefined
                }
                {...register("name", { required: true })}
              />
              <Input
                label="E-mail"
                error={
                  errors.email?.type === "required"
                    ? "E-mail jest wymagany."
                    : errors.email?.type === "pattern"
                    ? "E-mail jest nieprawidłowy."
                    : undefined
                }
                {...register("email", {
                  required: true,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
            </div>
            <Textarea
              label="Wiadomość"
              error={errors.message ? "Napisz wiadomość." : undefined}
              {...register("message", { required: true })}
            />
            <StyledReCaptchaWrapper>
              <ReCaptcha
                sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA}
                hl="pl"
                onChange={key => {
                  setCaptchaKey(key);
                  setWasSendTry(false);
                }}
              />
              {!captchaKey && wasSendTry && (
                <span>Potwierdź, że nie jesteś robotem</span>
              )}
            </StyledReCaptchaWrapper>

            <div>
              <StyledCheckbox
                label={`Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z wysłaniem zapytania przez formularz kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania`}
                error={errors.consent ? "Zgoda jest wymagana." : undefined}
                {...register("consent", { required: true })}
              />

              <StyledButton>Wyślij</StyledButton>
            </div>
          </StyledForm>
        ) : (
          <StyledAfterSendMessage $success={successfulSend}>
            {successfulSend
              ? "Twoja wiadomość została wysłana pomyślnie."
              : errorSend &&
                "Wystąpił nieoczekiwany błąd. Spróbuj ponownie później."}
          </StyledAfterSendMessage>
        )}
      </StyledLeftColumn>
      <StyledRightColumn>
        <StyledContactBlock
          href={datoCmsContact.googleLink}
          data-aos="zoom-in-left"
        >
          <Icon icon={markerIcon} />
          {locationArray[0]},
          <br />
          {locationArray[1]}
        </StyledContactBlock>
        <StyledContactBlock
          href={`tel:${datoCmsContact.phoneNumber}`}
          data-aos="zoom-in-left"
        >
          <Icon icon={phoneIcon} />
          {datoCmsContact.phoneNumber}
        </StyledContactBlock>
        <StyledContactBlock
          href={`mailto:${datoCmsContact.email}`}
          data-aos="zoom-in-left"
        >
          <Icon icon={emailIcon} />
          {datoCmsContact.email}
        </StyledContactBlock>
      </StyledRightColumn>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    datoCmsContact {
      phoneNumber
      email
      location
      googleLink
    }
  }
`;

export default Contact;
